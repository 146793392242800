import { render, staticRenderFns } from "./jkSixWay.vue?vue&type=template&id=42293396&scoped=true&"
import script from "./jkSixWay.vue?vue&type=script&lang=js&"
export * from "./jkSixWay.vue?vue&type=script&lang=js&"
import style0 from "./jkSixWay.vue?vue&type=style&index=0&id=42293396&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42293396",
  null
  
)

export default component.exports