<template>
  <!-- 集团介绍 -->
  <div>
    <NavBar></NavBar>
    <div class="introduction">
      <img alt="" class="img1" src="../../assets/image/about/img1.png"/>
      <el-row class="con">
        <el-col class="left">
          <el-row
              class="con_title"
              style="border-width: 0.5px 0.5px 0.5px 0;font-size:32px"
          >
            <div>集团介绍</div>
          </el-row
          >
          <el-row class="con_text" style="font-size:14px;line-height:26px">{{
              text1
            }}
          </el-row>
          <el-row
              class="con_text con_text2"
              style="font-size:14px;line-height:26px"
          >{{ text2 }}
          </el-row
          >
          <el-row class="con_text" style="font-size:14px;line-height:26px">{{
              text3
            }}
          </el-row>
        </el-col>

      </el-row>
      <el-row class="con">
        <el-col>
          <img style="width: 100%" src="../../assets/image/about/aboutbg.png" alt="">
        </el-col>
      </el-row>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default {
  data() {
    return {
      text1: `金恪控股集团股份有限公司（以下简称“金恪集团”）实缴注册资本人民币32亿元，是在多个行业拥有领先企业的大型产业投资运营集团，连年资信评级AA+。`,
      text2: `经过20多年的发展，金恪始终聚焦实体经济领域，紧紧围绕“医、食、住、行、养、娱”民生需求布局产业，构建“产业运营”与“消费营销”两大核心业务板块，通过技术创新、管理提升、金融服务、资源配置等能力，实现对高潜力产业及企业的投资及孵化，形成独特的品牌、渠道、营销核心竞争优势护城河，推动公司整体价值的持续增长，为市场提供更多优质的产品及服务，为股东提供长期优质回报，为地区发展储备产业集群，为中国经济高质量发展贡献力量。`,
      text3: `金恪集团是幸福生态的践行者。全力打造以家庭为核心的客户体系，全方位深度服务顾客；构建优秀的治理结构与企业文化，为员工创造多元的事业发展平台；积极承担社会责任，在乡村振兴、公益养老、兴边富民等领域进行长期持续投入，努力为国家和社会做出更多更大贡献。`,
    };
  },
  components: {
    Footer,
    NavBar,
  },
};
</script>
<style lang="scss">
.introduction {
  .el-col {
    padding: 0 !important;
  }
}
</style>

<style lang="scss" scoped>
.introduction {
  .img1 {
    width: 100%;
    display: block;
    height: 353px;
  }

  .con {
    padding: 0 375px 64px 416px;
    margin-top: 60px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .left {
      .con_text {
        font-weight: 400;
        color: #3e3a39;
        text-align: justify;
      }

      .con_text {
        margin: 20px 0;
      }

      .con_title {
        // font-size: 32px;
        line-height: 32px;
        color: #caa574;
        position: relative;
        width: 100%;
        height: 27px;
        border-color: #c8996c;
        border-style: solid;

        div {
          position: absolute;
          top: -25px;
          left: 0;
          background: #fff;
        }
      }
    }

    .img2 {
      margin-top: 13px;
      max-width: 407px;
      // height: 454px;
      display: block;
    }
  }
}
</style>
