<!--
 * @Author: jinzemin
 * @Date: 2021-04-26 09:54:08
 * @LastEditTime: 2021-05-10 15:32:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /jkoffical-ui/src/views/lg/home/index.vue
-->
<template>
  <div>
    <NavBar :activeIndex="activeIndex" />
    <div class="title">
      <p class="text">
        新闻资讯
        <router-link to="/lg/lgNews" class="more">了解更多</router-link>
      </p>
      <el-divider></el-divider>
    </div>
    <div class="newCont">
      <!-- 新闻轮播图 -->
      <div class="newCarouse">
        <el-carousel height="360px" ref="newList">
          <el-carousel-item v-for="item in lunboLlgNews" :key="item.id">
            <img style="width:100%;height:100%" :src="item.imageUrl" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="newList">
        <div class="newItem" v-for="item in news" :key="item.id">
          <p class="newTitle">
            <router-link :to="`/lg/lgNewsDetails/${item.id}`">
              {{ formatremark(item.title, 20) }}</router-link
            >
          </p>
          <p class="newDesc">
            {{ formatremark(item.descDetail, 40) }}
          </p>
        </div>
      </div>
    </div>
    <div class="title" id="shoot">
      <p class="text">
        拍摄场地
      </p>
      <el-divider></el-divider>
    </div>
    <div class="shoot" ref="shoot">
      <div class="shootCarouse">
        <el-carousel class="shoot_container" :height="heights">
          <el-carousel-item v-for="item in lunboAddressOne" :key="item.id">
            <img :src="item.imageUrl" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="shootDesc">
        辽阳孔雀岭小镇坐落于辽宁省辽阳县首山镇马家村中部，距辽阳市9公里，距鞍山市15公里。小镇所在的首山镇作为辽阳县经济、政治、文化交流中心，也是辽阳连接鞍山的交通枢纽，沈阳——大连发展轴的核心位置。小镇总占地4000余亩，以“山、水、田、园”自然资源为基底，将文旅与康养产业资源深度融合，集生态农业、文化休闲、旅游度假、医养大健康于一体，打造度假+养老+康养+农旅+金融的农文旅主题康养示范小镇，并在2020年成为《刘老根四》拍摄现场。
      </div>
      <div class="shootCont">
        <div class="shootItem">
          <div class="environment">
            <p class="shootTitle">周边环境</p>
            <p class="shootContDesc">
              孔雀岭小镇远离城市喧嚣，依山而居，傍水而栖，见山瞰水。在这里，看到的是青山绿树碧水蓝天，感受到的是舒心欢喜无比惬意，宜居感破屏而出。
            </p>
          </div>
          <div class="environmentCar">
            <el-carousel>
              <el-carousel-item v-for="item in lunboAddressTwo" :key="item.id">
                <img
                  style="width:100%;height:100%"
                  :src="item.imageUrl"
                  alt=""
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="shootItem">
          <div class="facilityCar">
            <el-carousel>
              <el-carousel-item
                v-for="item in lunboAddressThree"
                :key="item.id"
              >
                <img
                  style="width:100%;height:100%"
                  :src="item.imageUrl"
                  alt=""
                />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="facility">
            <p class="shootTitle">配套设施</p>
            <p class="shootContDesc">
              在孔雀岭小镇，金恪集团构建了一整套现代智慧智能系统，使其成为集生态农业、文化休闲、旅游度假、医养大健康于一体的的康养示范小镇。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="title">
      <p class="text">
        精彩视频
        <router-link to="/lg/lgVideo" class="more">了解更多</router-link>
      </p>
      <el-divider></el-divider>
    </div>
    <div class="video">
      <div class="videoCont">
        <div class="item" v-for="item in videoVos" :key="item.id">
          <a @click="goto(item.linkContent)">
            <img :src="item.imageUrl" alt="" />
          </a>
        </div>
      </div>
    </div>
    <div class="title">
      <p class="text">
        产品展示
      </p>
      <el-divider></el-divider>
    </div>
    <div class="product" id="product">
      <div class="productCont">
        <div class="item">
          <img src="../../../assets/image/lg/product_1@2x.png" alt="" />
          <div class="desc">
            <p class="brand">冠龙</p>
            <p class="name">有机干红葡萄酒-觞飞</p>
            <p class="code">扫码直购</p>
          </div>
        </div>
        <div class="item">
          <img src="../../../assets/image/lg/product_2@2x.png" alt="" />
          <div class="desc">
            <p class="brand">冠龙尼爵</p>
            <p class="name">有机干红葡萄酒</p>
            <p class="code">扫码直购</p>
          </div>
        </div>
        <div class="item">
          <img src="../../../assets/image/lg/product_3@2x.png" alt="" />
          <div class="desc">
            <p class="brand">罄玉漠琼</p>
            <p class="name">精选赤霞珠干红葡萄酒</p>
            <p class="code">扫码直购</p>
          </div>
        </div>
        <div class="item">
          <img src="../../../assets/image/lg/product_4@2x.png" alt="" />
          <div class="desc">
            <p class="brand">冠龙</p>
            <p class="name">有机干红葡萄酒-觞飞</p>
            <p class="code">扫码直购</p>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="productCont">
        <div class="item">
          <img src="../../../assets/image/lg/product_5@2x.png" alt="" />
          <div class="desc">
            <p class="brand">马头岩肉桂</p>
            <p class="name">茶叶礼盒 100g</p>
            <p class="code">扫码直购</p>
          </div>
        </div>
        <div class="item">
          <img src="../../../assets/image/lg/product_6@2x.png" alt="" />
          <div class="desc">
            <p class="brand">武夷山大红袍</p>
            <p class="name">茶叶礼盒 240g</p>
            <p class="code">扫码直购</p>
          </div>
        </div>
        <div class="item">
          <img src="../../../assets/image/lg/product_7@2x.png" alt="" />
          <div class="desc">
            <p class="brand">榧常好食</p>
            <p class="name">三代果尚品玉榧 216克</p>
            <p class="code">扫码直购</p>
          </div>
        </div>
        <div class="item">
          <img src="../../../assets/image/lg/product_8@2x.png" alt="" />
          <div class="desc">
            <p class="brand">香榧黑糖松饼</p>
            <p class="name">芝麻燕麦饼干</p>
            <p class="code">扫码直购</p>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="productCont">
        <div class="item">
          <img src="../../../assets/image/lg/product_9@2x.png" alt="" />
          <div class="desc">
            <p class="brand">尚蟹世家</p>
            <p class="name">洪泽湖大闸蟹卡</p>
            <p class="code">扫码直购</p>
          </div>
        </div>
        <div class="item">
          <img src="../../../assets/image/lg/product_10@2x.png" alt="" />
          <div class="desc">
            <p class="brand">燃卡多多代餐计划</p>
            <p class="name">能量套餐</p>
            <p class="code">扫码直购</p>
          </div>
        </div>
        <div class="item"></div>
        <div class="item"></div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/lgNavBar";
import Footer from "@/components/Footer";
export default {
  name: "Home",
  data() {
    return {
      activeIndex: "/lg/home",
      lunboTop: [],
      news: [],
      lunboAddressOne: [],
      lunboAddressTwo: [],
      lunboAddressThree: [],
      lunboLlgNews: [],
      videoVos: [],
      heights: "0",
    };
  },
  mounted() {
    this.getCarouseList();
    this.heights = (1120 * 380) / this.$refs.shoot.clientWidth + "px";
  },
  watch: {
    $route(val) {
      let path = val.path;
      this.activeIndex = path;
      if (path === "/lg/shoot") {
        this.activeIndex = path;
        this.$nextTick(() => {
          document.getElementById("shoot").scrollIntoView();
        });
      } else if (path === "/lg/product") {
        this.activeIndex = path;
        this.$nextTick(() => {
          document.getElementById("product").scrollIntoView();
        });
      }
    },
  },
  methods: {
    async getCarouseList() {
      try {
        let res = await this.$axios.get("/app/llg/index/content");
        if (res.status == 200 && res.data.code == 0) {
          let {
            lunboTop,
            news,
            lunboAddressOne,
            lunboAddressTwo,
            lunboAddressThree,
            lunboLlgNews,
            videoVos,
          } = res.data.data;
          this.lunboTop = lunboTop;
          this.news = news;
          this.lunboAddressOne = lunboAddressOne;
          this.lunboAddressTwo = lunboAddressTwo;
          this.lunboAddressThree = lunboAddressThree;
          this.lunboLlgNews = lunboLlgNews;
          this.videoVos = videoVos;
          console.log(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    formatremark(val, num) {
      if (val != null) {
        if (val.length > num) {
          return val.substring(0, num) + "......";
        } else {
          return val;
        }
      }
    },
    goto(url) {
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      a.click();
    },
  },
  created() {},
  components: {
    Footer,
    NavBar,
  },
};
</script>

<style scoped lang="scss">
.title {
  margin-top: 40px;
  overflow: hidden;
  .text {
    position: relative;
    width: 1200px;
    font-size: 34px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    line-height: 34px;
    color: #414e56;
    text-align: center;
    height: 40px;
    margin: 0px auto;
    .more {
      position: absolute;
      right: 0;
      bottom: 4px;
      background-color: #80bc9b;
      border-radius: 20px;
      text-align: center;
      line-height: 14px;
      padding: 10px 20px;
      font-size: 14px;
      color: #fff;
      text-decoration: none;
    }
  }
  .el-divider {
    background-color: #80bc9b;
    margin: 0;
  }
}
.newCont {
  width: 1200px;
  margin: 42px auto;
  display: flex;
  justify-content: space-evenly;
  .newCarouse {
    width: 540px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .newList {
    width: 544px;
    .newItem {
      padding: 20px;
      border-bottom: 1px dashed #b5866c;
      .newTitle {
        a {
          font-size: 20px;
          font-weight: 400;
          color: #b5866c;
          text-decoration: none;
        }
      }
      .newDesc {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        line-height: 28px;
        font-weight: 400;
        color: #414e56;
        padding: 18px 0 0 0;
      }
    }
  }
}
.shoot {
  width: 1200px;
  margin: 20px auto;
  .shootCarouse {
    img {
      width: 100%;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
  .shootDesc {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #595757;
    line-height: 28px;
    margin: 28px 0;
  }
  .shootCont {
    width: 1200px;
    margin: 20px auto;
    .shootItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .environment {
        width: 480px;
        height: 300px;
        background-color: #c4decc;
        padding: 0 35px;
        .shootTitle {
          margin-top: 80px;
          font-size: 24px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
        }
        .shootContDesc {
          margin-top: 20px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #595757;
          line-height: 28px;
        }
      }
      .environmentCar {
        width: 630px;
        .environment_container {
          img {
            width: 100%;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
          }
        }
      }
      .facility {
        width: 480px;
        height: 300px;
        background-color: #b7dcf4;
        padding: 0 35px;
        .shootTitle {
          margin-top: 80px;
          font-size: 24px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
        }
        .shootContDesc {
          margin-top: 20px;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #595757;
          line-height: 28px;
        }
      }
      .facilityCar {
        width: 630px;
      }
      .facility_container {
        img {
          width: 100%;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
.video {
  width: 1200px;
  margin: 0 auto;
  .videoCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
    .item {
      height: 264px;
      width: 350px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.product {
  width: 1200px;
  margin: 0 auto;
  .divider {
    border-bottom: 1px dashed #b5866c;
  }
  .productCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px auto;
    .item {
      height: 450px;
      width: 266px;
      position: relative;
      .desc {
        position: absolute;
        left: 10px;
        bottom: 0;
        .brand,
        .name {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #211815;
          line-height: 28px;
        }
        .code {
          margin-top: 10px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #9c9d9d;
        }
      }
      img {
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>
