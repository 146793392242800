<template>
  <!-- 发展历程 -->
  <div>
    <NavBar></NavBar>
    <div class="development">
      <img class="img1" src="../../assets/image/about/about2-1.png" alt="" />
      <div class="con">
        <div
          class="con_title"
          style="border-width: 0.5px 0.5px 0.5px 0;font-size:32px"
        >
          <span>发展历程</span>
        </div>
        <div class="text" style="font-size:14px;line-height:26px">
          {{ text }}
        </div>
        <img class="img2" src="../../assets/image/about/about2-2.png" alt="" />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  data() {
    return {
      text: `金恪集团的成长发展经历了中国经济增长最快的20年，经历了中国人民收入水平和生活水平飞速提高的20年。经过不断战略探索与产业实践，金恪始终相信人的需求是经济发展的价值本源。`,
    };
  },
  components: {
    Footer,
    NavBar,
  },
};
</script>

<style lang="scss" scoped>
.development {
  .img1 {
    width: 100%;
    height: 353px;
    display: block;
  }
  .con {
    padding: 60px 0 43px 415px;
    .con_title {
      width: 1098px;
      height: 27px;
      position: relative;
      border-color: #c8996c;
      border-style: solid;
      span {
        display: block;
        position: absolute;
        left: 0px;
        top: -20px;
        background: #ffffff;
        line-height: 32px;
        font-weight: 400;
        color: #caa574;
      }
    }
    .text {
      font-weight: 400;
      color: #3e3a39;
      line-height: 28px;
      margin: 20px 0 58px 0;
      text-align: justify;
      width: 1098px;
    }
    .img2 {
      padding-left: 0px;
      padding-right: 401px;
      display: block;
      width: 100%;
      box-sizing: border-box;
    }
  }
}
</style>
