const navlist = [
  {
    title: '关于金恪',
    path:'/about',
    id:0,
    children:[
      {
        index: 0,
        name: '集团介绍',
        path:'/about'
      },
      {
        index: 1,
        name: '发展历程',
        path:'/about/development'
      },
      {
        index: 2,
        name: '管理之道',
        path:'/about/management'
      },
      {
        index: 3,
        name: '金恪六道',
        path:'/about/jkSixWay'
      }
    ]
  },
  {
    title: '新闻动态',
    path:'/news/index',
    id:1,
  },
  {
    title: '产业布局',
    path:'/core/happiness',
    id:2,
    children:[
      {
        index: 0,
        name: '幸福产业',
        path:'/core/happiness'
      },
      {
        index: 1,
        name: '消费营销',
        path:'/core/consume'
      },
      {
        index: 2,
        name: '绿色发展',
        path:'/core/greenDevelopment'
      },
      {
        index: 3,
        name: '科技创新',
        path:'/core/science'
      },
      {
        index: 3,
        name: '资本赋能',
        path:'/core/capital'
      },
      // {
      //   index: 2,
      //   name: '大医养',
      //   path:'/core/care'
      // },
      // {
      //   index: 3,
      //   name: '产业地产',
      //   path:'/core/estate'
      // },
      // {
      //   index: 4,
      //   name: '营销体系',
      //   path:'/empower/marketing'
      // },
      // {
      //   index: 5,
      //   name: '产业投资',
      //   path:'/empower/investment'
      // },
    ]
  },
  // {
  //   title: '金恪文化',
  //   path:'/jkCulture/sixWays',
  //   id:3,
  //   children:[
  //     {
  //       index: 0,
  //       name: '金恪六道',
  //       path:'/jkCulture/sixWays'
  //     },
  //     {
  //       index: 1,
  //       name: '金恪合伙人',
  //       path:'/jkCulture/partner'
  //     },
  //   ]
  // },
  {
    title:'品牌故事',
    path:'/brand',
    id:3,
  },
  {
    title:'社会责任',
    path:'/duty',
    id:4,
  },
  {
    title:'企业荣誉',
    path:'/honor',
    id:5,
  }
]


export default navlist