<template>
  <!-- 消费贸易 -->
  <div>
    <NavBar></NavBar>
    <div class="trading">
      <img
        class="img1"
        src="../../assets/image/incubation/incubation-3-1.png"
        alt=""
      />
      <el-row class="con">
        <el-col class="left">
          <div
            class="nav_item"
            v-for="(item, i) in leftList"
            :key="i"
            @click.stop="selectItem(i)"
          >
            <p>{{ item.name }}</p>
            <img
              class="icon"
              v-if="i == index"
              src="../../assets/image/core/core-1-8.png"
              alt=""
            />
          </div>
        </el-col>
        <el-col class="right">
          <div class="rightItem">
            <div class="con_title">
              <span>消费贸易</span>
            </div>
            <img
              class="img2"
              src="../../assets/image/incubation/incubation-3-2.png"
              alt=""
            />
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text1 }}
            </div>
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text2 }}
            </div>
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text3 }}
            </div>
          </div>
          <div class="elrow">
            <div class="type rightItem" style="margin-right:22px">
              <div class="title">国乾优品</div>
              <img
                class="img3"
                src="../../assets/image/incubation/incubation-3-3.png"
                alt=""
              />
              <div class="text" style="font-size:14px;line-height:26px">
                {{ text4 }}
              </div>
            </div>
            <div class="type rightItem">
              <div class="title">巍格智能&奥芯思智能</div>
              <img
                class="img3"
                src="../../assets/image/incubation/incubation-3-4.png"
                alt=""
              />
              <div class="text" style="font-size:14px;line-height:26px">
                {{ text5 }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  data() {
    return {
      text1: `国乾科技作为智慧型供应链服务的创新者，不断完善供应链服务模式，致力于打造一个跨界融合的智慧型供应链生态圈。`,
      text2: `国乾科技以产业+科技为服务，以资源整合为手段，整合供应链生态圈各产业链，融入品牌方市场、渠道及销售政策，形成品牌传播和渠道营销的循环对接，构建智慧型供应链。`,
      text3: `客户服务覆盖全国，布局全球，其中涵盖了科技创新、大宗贸易、新零售、农业、文旅、医养大健康等众多领域；并与华为HUAWEI、苹果Apple、飞利浦Phillips等众多中高端品牌达成战略合作伙伴。`,
      text4: `“新零售”国乾优品，甄选全球3C数码轻奢品，打造智能轻奢品质生活。以理解并满足本土化需求建立快速响应能力，通过3C数码、奢侈品、优选好物、服饰、钟表、汽车、美妆七大垂直事业部布局线上及线下零售店；以建立闭环生态，提升技术能力资源整合为品牌策略。`,
      text5: `国乾科技旗下全新自有品牌“GQViegré 巍格智能”、“Ocinsis奥芯思智能”，是国乾科技围绕“医、食、住、行、养、娱”，以私有云概念以及消费电子智能家居为切入点，打造的国乾特色的智能生态圈。秉持物联一切的精品智能生活理念，国乾科技将围绕基础建设，打造个人的“云之家”`,
      leftList: [
        { name: "消费贸易", id: 0 },
        { name: "国乾优品", id: 1 },
        { name: "巍恪智能&奥芯思智能", id: 2 },
      ],
      index: 0,
      element: {
        nav_item: null,
        right_item: null,
      },
      timer: null,
    };
  },
  components: {
    Footer,
    NavBar,
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.rightScrollEventListener);
  },
  methods: {
    // 初始化
    init() {
      this.element = {
        nav_item: document.getElementsByClassName("nav_item"),
        right_item: document.getElementsByClassName("rightItem"),
      };
      this.element.nav_item[0].classList.add("colorActive");
      window.addEventListener("scroll", this.rightScrollEventListener);
    },
    // 页面滚动监听,定位导航
    rightScrollEventListener() {
      const { nav_item, right_item } = this.element;
      let window_scrollTop =
        document.scrollTop || document.documentElement.scrollTop;

      for (let i = 0; i < right_item.length; i++) {
        if (window_scrollTop >= right_item[0].offsetTop + 100) {
          document.getElementsByClassName("left")[0].classList.add("leftFixed");
        } else {
          document
            .getElementsByClassName("left")[0]
            .classList.remove("leftFixed");
        }
        let item_offsetTop = right_item[i].offsetTop;

        if (window_scrollTop >= item_offsetTop) {
          for (let n = 0; n < nav_item.length; n++) {
            const current_classList = nav_item[n].classList;
            if (i === n) {
              current_classList.add("colorActive");
              this.index = n;
            } else {
              current_classList.remove("colorActive");
            }
          }
        }
      }
    },
    // 楼层导航驱动方法
    selectItem(i) {
      const { right_item } = this.element;
      let rightItem_offsetTop = right_item[i].offsetTop + 50;
      let window_scrollTop =
        document.scrollTop || document.documentElement.scrollTop;
      let timer = {
        step: 20,
        times: 10,
        rightItem_offsetTop,
      };
      if (window_scrollTop < rightItem_offsetTop) {
        this.setScrollArrowUp(timer);
      } else if (window_scrollTop == rightItem_offsetTop) {
        return false;
      } else {
        this.setScrollArrowDown(timer);
      }
    },
    // 设置页面滚动
    setScrollArrowUp(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop <= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop - v.step;
          document.body.scrollTop = window_scrollTop - v.step;
        }
      }, v.times);
    },
    // // 设置页面向下滚动
    setScrollArrowDown(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop >= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop + v.step;
          document.body.scrollTop = window_scrollTop + v.step;
        }
      }, v.times);
    },
  },
};
</script>

<style lang="scss" scoped>
.trading {
  .img1 {
    width: 100%;
    height: 300px;
    display: block;
  }
  .con {
    .left {
      width: 198px;
    }
    .nav_item {
      color: #3e3a39;
      width: 198px;
      position: relative;
      :hover {
        cursor: pointer;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 47px;
        height: 46px;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        text-align: center;
      }
      .icon {
        display: block;
        width: 198px;
        height: 47px;
        position: absolute;
        left: 0;
        top: 0;
      }
      .fcolor {
        color: #c8a062;
      }
    }
    .right {
      padding: 89px 360px 44px 666px;
      .con_title {
        width: 100%;
        height: 27px;
        border-bottom: 1px solid #e7e7e7;
        border-top: 1px solid #e7e7e7;
        border-right: 1px solid #e7e7e7;
        border-left: 1px solid #e7e7e7;
        position: relative;
        span {
          display: block;
          position: absolute;
          left: 0px;
          top: -20px;
          background: #ffffff;
          font-size: 32px;
          line-height: 32px;
          font-weight: 400;
          color: #3e3a39;
          width: 200px;
          padding-left: 30px;
          box-sizing: border-box;
        }
      }
      .img2 {
        width: 100%;
        height: 293px;
        margin-top: 20px;
      }
      .text {
        // font-size: 14px;
        text-align: justify;
        font-weight: 400;
        color: #3e3a39;
        line-height: 26px;
        margin-bottom: 20px;
      }
      .img3 {
        height: 206px;
        width: 100%;
        margin: 16px 0 0 0;
      }
      .title {
        font-size: 24px;
        font-weight: 400;
        color: #3e3a39;
        line-height: 24px;
      }
      .text {
        // font-size: 14px;
        text-align: justify;
        font-weight: 400;
        color: #3e3a39;
        line-height: 26px;
        margin: 20px 0 17px 0;
      }
      .elrow {
        display: flex;
        direction: row;
        justify-content: space-between;
        margin-top: 82px;
        .type {
          width: 435px;
        }
      }
    }
  }
  .left {
    position: absolute;
    top: 80px;
    left: 360px;
  }
  .leftFixed {
    position: fixed !important;
    left: 360px !important;
    top: 140px !important;
  }
  .colorActive {
    color: #c8a062 !important;
  }
}
</style>
