<template>
  <!-- 幸福养心 -->
  <div>
    <NavBar></NavBar>
    <div class="care">
      <img
        class="img1"
        src="../../assets/image/incubation/incubation-1-4.png"
        alt=""
      />
      <el-row class="con">
        <el-col class="left">
          <div
            class="nav_item"
            v-for="(item, i) in leftList"
            :key="i"
            @click.stop="selectItem(i)"
          >
            <p>{{ item.name }}</p>
            <img
              class="icon"
              v-if="i == index"
              src="../../assets/image/core/core-1-8.png"
              alt=""
            />
          </div>
        </el-col>
        <el-col class="right">
          <div class="rightItem">
            <img
              class="img2"
              src="../../assets/image/incubation/incubation-5-1.png"
              alt=""
            />
          </div>
          <div class="rightItem">
            <div class="con_title">
              <span>文化事业</span>
            </div>
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text1 }}
            </div>
            <img
              class="img3"
              src="../../assets/image/incubation/incubation-5-2.png"
              alt=""
            />
          </div>
          <div class="rightItem">
            <div class="con_title">
              <span>教育事业</span>
            </div>
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text2 }}
            </div>
            <img
              class="img4"
              src="../../assets/image/incubation/incubation-5-3.png"
              alt=""
            />
          </div>
          <div class="rightItem">
            <div class="con_title">
              <span>公益事业</span>
            </div>
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text3 }}
            </div>
            <div class="text text1" style="font-size:16px;line-height:26px">
              {{ text4 }}
            </div>
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text5 }}
            </div>
            <div class="text text1" style="font-size:16px;line-height:26px">
              {{ text6 }}
            </div>
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text7 }}
            </div>
            <img
              class="img5"
              src="../../assets/image/incubation/incubation-5-4.png"
              alt=""
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  data() {
    return {
      text1: `金恪文化是集团在全周期幸福生态布局的重要一环，聚焦内容、平台及技术，全方位整合影视、综艺、实景娱乐、艺术典藏及高端品牌等头部行业资源以及 “幸福养心”产业体系，与集团的医、食、住、行、养等产业深度融合及联动，打造独特“文化+产业”的金恪模式`,
      text2: `金恪教育产业是实现幸福养心理论及体系的基石产业，融合集团各产业优势，以“信仰+素质+能力”复合教育为出发点，提供精神、文化、艺术、技能等多维度内容，通过医食住行养娱线上、线下“产业+场景”的创新教育平台，全方位提升个人修养和智慧，并建立社交群体效应，提升幸福感。`,
      text3: `基于集团强大产、投、研资源，配合集团发展战略，秉承集团及董事局“用公益的心态做企业，用企业的形态做公益”的公益慈善理念，践行企业社会责任，彰显企业社会情怀。`,
      text4: `无私支持，助社会公益事业一臂之力：`,
      text5: `践行企业社会责任，为公益行业提供资金、场地及人力支持，助力公益组织在环保、扶贫、科技创新、为老服务等领域公益项目实施；同时关注公益行业人才培养，助力公益行业可持续发展。`,
      text6: `义利并举，为小微企业插上梦想翅膀：`,
      text7: `义利并举，坚持回报率与社会影响力并重，关注实现回报率的同时，又能对社会和环境产生正面影响的小微企业或项目，使公益与商业深度融合，并依托集团的“医、食、住、行、养”等产业资源，整合供求关系，助力该类企业健康成长。`,
      leftList: [
        { name: "文化事业", id: 0 },
        { name: "教育事业", id: 1 },
        { name: "公益事业", id: 2 },
      ],
      index: 0,
      element: {
        nav_item: null,
        right_item: null,
      },
      timer: null,
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.rightScrollEventListener);
  },
  components: {
    Footer,
    NavBar,
  },
  methods: {
    // 初始化
    init() {
      this.element = {
        nav_item: document.getElementsByClassName("nav_item"),
        right_item: document.getElementsByClassName("rightItem"),
      };
      this.element.nav_item[0].classList.add("colorActive");
      window.addEventListener("scroll", this.rightScrollEventListener);
    },
    // 页面滚动监听,定位导航
    rightScrollEventListener() {
      const { nav_item, right_item } = this.element;
      let window_scrollTop =
        document.scrollTop || document.documentElement.scrollTop;
      // eslint-disable-next-line no-unused-vars
      const con = document.getElementsByClassName("con")[0].offsetTop;

      for (let i = 0; i < right_item.length; i++) {
        if (window_scrollTop >= right_item[0].offsetTop + 100) {
          document.getElementsByClassName("left")[0].classList.add("leftFixed");
        } else {
          document
            .getElementsByClassName("left")[0]
            .classList.remove("leftFixed");
        }
        let item_offsetTop = right_item[i].offsetTop + 20 * i;

        if (window_scrollTop >= item_offsetTop) {
          for (let n = 0; n < nav_item.length; n++) {
            const current_classList = nav_item[n].classList;
            if (i === n) {
              current_classList.add("colorActive");
              this.index = n;
            } else {
              current_classList.remove("colorActive");
            }
          }
        }
      }
    },
    // 楼层导航驱动方法
    selectItem(i) {
      const { right_item } = this.element;
      let rightItem_offsetTop = right_item[i].offsetTop + 50;
      let window_scrollTop =
        document.scrollTop || document.documentElement.scrollTop;
      let timer = {
        step: 50,
        times: 10,
        rightItem_offsetTop,
      };
      if (window_scrollTop < rightItem_offsetTop) {
        this.setScrollArrowUp(timer);
      } else if (window_scrollTop == rightItem_offsetTop) {
        return false;
      } else {
        this.setScrollArrowDown(timer);
      }
    },
    // 设置页面滚动
    setScrollArrowUp(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop <= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop - v.step;
          document.body.scrollTop = window_scrollTop - v.step;
        }
      }, v.times);
    },
    // // 设置页面向下滚动
    setScrollArrowDown(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop >= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop + v.step;
          document.body.scrollTop = window_scrollTop + v.step;
        }
      }, v.times);
    },
  },
};
</script>

<style lang="scss" scoped>
.care {
  .img1 {
    width: 100%;
    height: 300px;
    display: block;
  }
  .con {
    .left {
      width: 198px;
    }
    .nav_item {
      color: #3e3a39;
      width: 198px;
      position: relative;
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 47px;
        height: 47px;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        text-align: center;
      }
      .icon {
        display: block;
        width: 198px;
        height: 47px;
        position: absolute;
        left: 0;
        top: 0;
      }
      .fcolor {
        color: #c8a062;
      }
    }
    .right {
      padding: 89px 360px 136px 666px;
      .con_title {
        width: 100%;
        height: 27px;
        border-bottom: 1px solid #e7e7e7;
        border-top: 1px solid #e7e7e7;
        border-right: 1px solid #e7e7e7;
        border-left: 1px solid #e7e7e7;
        position: relative;
        margin-top: 69px;
        span {
          display: block;
          position: absolute;
          left: 0px;
          top: -20px;
          background: #ffffff;
          font-size: 32px;
          line-height: 32px;
          font-weight: 400;
          color: #3e3a39;
          width: 200px;
          padding-left: 30px;
          box-sizing: border-box;
        }
      }
      .img2 {
        width: 100%;
        height: 399px;
      }
      .img3 {
        width: 100%;
        height: 353px;
      }
      .img4 {
        width: 100%;
        height: 406px;
      }
      .img5 {
        width: 100%;
        height: 240px;
      }
      .text {
        // font-size: 14px;
        text-align: justify;
        font-weight: 400;
        color: #3e3a39;
        line-height: 26px;
        margin: 30px 0 30px 0;
      }
      .text1 {
        color: #d2a36c;
      }
    }
  }
  .left {
    position: absolute;
    top: 80px;
    left: 360px;
  }
  .leftFixed {
    position: fixed !important;
    left: 360px !important;
    top: 140px !important;
  }
  .colorActive {
    color: #c8a062 !important;
  }
}
</style>
