<template>
  <!-- 孵化产业群 -->
  <div>
    <NavBar></NavBar>
    <div class="incubation">
      <div class="carousel">
        <el-carousel trigger="click" arrow="never" height="380">
          <el-carousel-item
            v-for="item in carouseList"
            :key="item.id"
            class="carouse"
          >
            <el-image class="elimg" :src="item.path"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="con">
        <div class="con_title">
          <span>孵化产业群</span>
        </div>
        <div class="text" style="font-size:14px;line-height:26px">
          {{ text }}
        </div>
        <div class="core_img">
          <img
            src="../../assets/image/incubation/incubation-1.1.png"
            @click="goDetail(1)"
            alt=""
          />
          <img
            src="../../assets/image/incubation/incubation-1.2.png"
            @click="goDetail(2)"
            alt=""
          />
          <img
            src="../../assets/image/incubation/incubation-1.3.png"
            @click="goDetail(3)"
            alt=""
          />
          <img
            src="../../assets/image/incubation/incubation-1.4.png"
            @click="goDetail(4)"
            alt=""
          />
          <img
            src="../../assets/image/incubation/incubation-1.5.png"
            @click="goDetail(5)"
            alt=""
          />
          <img
            src="../../assets/image/incubation/incubation-1.6.png"
            @click="goDetail(6)"
            alt=""
          />
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  data() {
    return {
      text: `多年来，围绕核心产业群的发展，以提供服务与支持为目标，金恪产业布局展开多元化协同探索。以智慧科技、消费贸易、企业服务系统、文化教育产业、公益事业为代表的孵化产业群发展取得初步成效，不仅保障实现幸福产业生态体系的布局与升级，更培育出企业新的效益增长点。`,
      carouseList: [
        {
          path: require("@/assets/image/incubation/incubation-1-1.png"),
        },
        {
          path: require("@/assets/image/incubation/incubation-1-2.png"),
        },
        {
          path: require("@/assets/image/incubation/incubation-1-3.png"),
        },
        {
          path: require("@/assets/image/incubation/incubation-1-4.png"),
        },
      ],
    };
  },
  components: {
    Footer,
    NavBar,
  },
  methods: {
    goDetail(v) {
      if (v == 1) {
        this.$router.push("/incubation/technology");
      } else if (v == 2) {
        this.$router.push("/incubation/trading");
      } else if (v == 3) {
        this.$router.push("/incubation/service");
      } else if (v == 4) {
        this.$router.push("/incubation/ntHeart");
      } else if (v == 5) {
        this.$router.push("/incubation/ntHeart");
      } else {
        this.$router.push("/incubation/ntHeart");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.incubation {
  .carousel {
    width: 100%;
    height: 380px;
    display: block;
    .elimg {
      width: 100%;
      height: 380px;
    }
  }
  .img1 {
    width: 100%;
    height: 353px;
    display: block;
  }
  .con {
    padding: 67px 0 62px 415px;
    width: 1196px;
    .con_title {
      width: 100%;
      height: 27px;
      border-bottom: 1px solid #e0e0e0;
      border-top: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      position: relative;
      span {
        display: block;
        position: absolute;
        left: 0px;
        top: -20px;
        background: #ffffff;
        font-size: 32px;
        line-height: 32px;
        font-weight: 400;
        color: #3e3a39;
      }
    }
    .text {
      // font-size: 14px;
      text-align: justify;
      font-weight: 400;
      color: #3e3a39;
      line-height: 26px;
      margin-top: 44px;
      margin-bottom: 56px;
    }
    .core_img {
      width: 100%;
      display: flex;
      direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      img {
        width: 392px;
        height: 214px;
        display: block;
        margin-bottom: 9px;
        cursor: pointer;
      }
    }
  }
}
</style>
