<template>
  <!-- 营销体系 -->
  <div>
    <NavBar></NavBar>
    <div class="care">
      <img
        class="img1"
        src="../../assets/image/empower/impower-3-1.png"
        alt=""
      />
      <el-row class="con">
        <el-col class="left">
          <div
            class="nav_item"
            v-for="(item, i) in leftList"
            :key="i"
            @click.stop="selectItem(i)"
          >
            <p>{{ item.name }}</p>
            <img
              class="icon"
              v-if="i == index"
              src="../../assets/image/core/core-1-8.png"
              alt=""
            />
          </div>
        </el-col>
        <el-col class="right">
          <div class="rightItem">
            <div class="con_title">
              <span>营销体系</span>
            </div>
            <img
              class="img2"
              src="../../assets/image/empower/impower-3-2.png"
              alt=""
            />
            <div class="text" style="font-size:14px;line-height:26px">
              金恪集团营销体系是金恪旗下大农业、大文旅、大医养等全产业产品、服务的消费入口。营销体系整合“医、食、住、行、养、娱”各产业链条销售端口，通过以场景为主、“线上+线下”相结合的体系，针对内部各产业发展实现支持带动；对外部会员、投资者、政府、社区等进行个性化、定制化、系统化的产品及服务营销。
            </div>
          </div>
          <div class="type rightItem">
            <div class="title mt45">幸福通</div>
            <div class="text" style="font-size:14px;line-height:26px">
              幸福通作为金恪集团产业的创新营销渠道和主要营销业务版块，致力于为集团会员个人及家庭提供个性化、系统化的“医、食、住、行、养、娱”产品及服务全面解决方案，打造集医养、文旅、消费零售、社交娱乐于一体的一站式会员制商业复合生态，通过线上会员商城与线下生活方式体验店“线上+线下”相结合的消费服务场景，围绕用户拉新、活跃、留存、转化等重要节点，不断开创订单抽奖、接元宝、抽盲盒、开福袋、新人专享、会员专享、限时秒杀、幸福周末、直播带货、社群运营、会员积分激励成长计划等创新营销活动和玩法，助力业绩提升，高效协同各产业端业务发展。
            </div>
            <img
              class="img3"
              src="../../assets/image/empower/impower-3-3.png"
              alt=""
            />
          </div>
          <div class="type rightItem">
            <div class="title mt30">食恪生鲜</div>
            <div class="text" style="font-size:14px;line-height:26px">
              食恪生鲜是金恪旗下大型连锁生鲜综合体，2017年启用门店和线上平台。目前在上海已有3家门店，并且在辽阳市入驻了6000平米生鲜综合体。
            </div>
            <img
              class="img4"
              src="../../assets/image/empower/impower-3-4.png"
              alt=""
            />
          </div>
          <!-- <div class="type2">
          <div class="type wh rightItem">
            <div class="title">福歆医药</div>
            <div class="text mb45" style="font-size:14px;line-height:26px">{{text5}}</div>
            <img class="img4 " src="../../assets/image/empower/impower-3-5.png" alt="">
          </div>
          <div class="type wh rightItem">
            <div class="title">壹娘农品</div>
            <div class="text" style="font-size:14px;line-height:26px">{{text6}}</div>
            <img class="img4" src="../../assets/image/empower/impower-3-6.png" alt="">
          </div>
        </div> -->
          <!-- <div class="type2 rightItem">
            <div class="type sy">
              <img
                class="img5"
                src="../../assets/image/empower/impower-3-7.png"
                alt=""
              />
            </div>
            <div class="type st">
              <div class="title">金恪营销学院</div>
              <div class="text" style="font-size:14px;line-height:26px">
                金恪营销学院，作为金恪营销集团的营销赋能平台、组织绩效推动平台和企业文化传承传播平台，对内为金恪营销集团一线人员赋能培训，课程内容涵盖营销培训、狼性文化、渠道策略、营销管理等方面，并组织开展了“营销铁军训练营”、“十公里健康跑”等培训项目，旨在提升一线员工战斗力、执行力和凝聚力。对外，学院为中小企业主、创业者、投资者提供经济形势分析、行业研究、资产配置建议、财务专业咨询等多元服务，同时营造合作交流和融资创投的氛围。
              </div>
            </div>
          </div>
          <div class="type3 rightItem">
            <div class="title">金恪幸福农庄连锁加盟</div>
            <div class="type3_con">
              <div class="type3_left">
                <div class="text" style="font-size:14px;line-height:26px">
                  金恪幸福农庄连锁加盟平台包含品牌、管理、产品、认证、客流、资本六大输出模式及幸福集市、食药同源及资产权益三大亮点。
                  农庄以“品牌输出”统一拓展“金恪幸福农庄”品牌，以“管理输出”贯彻现代规范管理体系，以“产品输出”推广标准化幸福农庄产品，以“认证输出”提供资产认证及增值服务，以“客流输出”实现幸福通会员导流，并通过认养-众筹实现资本赋能。
                  同时，幸福农庄通过打造“幸福集市”构建幸福通会员之家；通过“食药同源”原理，制定健康食养解决方案，并以“资产权益”模式作为农庄融资的最佳途径。
                </div>
              </div>
              <div class="type3_right">
                <img src="../../assets/image/empower/impower-3-8.png" alt="" />
              </div>
            </div>
          </div> -->
        </el-col>
      </el-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  data() {
    return {
      text5: `福歆医药是金恪旗下药品零售平台。目前拥有10余家实体门店，主要经营中成药、中药饮片、生物制品，日用百货、化妆品销售、商务咨询、展览展示服务，营养健康咨询服务等。`,
      text6: `壹娘农品在全国范围内展开农特产品产业的整合，甄选更具性价比和区域特色的生态自然农产品，打造出优质农产品品牌。目前旗下拥有新疆红枣、金华火腿、黑蜂原蜜等多个特色农产品品类，并开发出 “小鱼儿”、“我的果”等休闲零食品牌。`,
      text7: `精房通平台集合多家知名地产公司的产品，可实现商品房+养老公寓+度假酒店等多种楼盘类型一站式选择。平台涵盖全维度楼盘信息，帮助用户更好做出购买策略。`,
      text8: ` 地图找房，全局查看，不错过任何一个价值洼地，足不出户，在线全景看房，100%真实，犹如身在现场。直播看房，看交通、看设施、看户型、看宅配；置业顾问解答详细信息；还可在线可签订认购协议，并享有独家优惠。`,
      text9: `金恪一品借助「MCN+新电商」模式，将内容管理与产品营销相结合，以新疆优质有机红酒，香榧及深加工产品等优势资源为基础，通过自建平台、直播平台，创建时尚圈社交商业网络，提供营销解决方案，以电商供应链、流量导入等方式完善金恪一品线上+线下产品的商业利润转化闭环，并充分运用“新零售”模式，借助“新物流”助推业态快速发展。`,
      text10: `“尊恪荟”是集成互联网技术与大数据优势而开发的微信端服务平台。该平台通过整合全球知名品牌酒店、景点、大型游乐场、运动场馆、航空、豪车试驾旅游等多领域消费资源，以多种方式向高净值人群、高端会员企业提供高品质的时尚消费权益，为高净值人群打造在线一站式自助管理，同时为高端会员企业提供企业与会员之间快速精准的信息交互通道，提高友好度和降低操作成本。`,
      text11: ` 合作机构可自由选择组合权益产品与权益使用的方式，仅需提供销售系统，“尊恪荟”将负责数据筛选供给，审核业务规划策略，每月核销、对账等，真正实现客户零投入享受一站式权益整合营销服务。`,
      leftList: [
        { name: "营销体系", id: 0 },
        { name: "幸福通", id: 1 },
        { name: "食恪生鲜", id: 2 },
        // { name: "金恪营销学院", id: 3 },
        // { name: "金恪幸福农庄连锁加盟", id: 4 },
        // { name: "精房通", id: 5 },
        // { name: "金恪一品", id: 6 },
        // { name: "尊恪荟", id: 7 },
      ],
      index: 0,
      element: {
        nav_item: null,
        right_item: null,
      },
      timer: null,
    };
  },
  components: {
    Footer,
    NavBar,
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.rightScrollEventListener);
  },
  methods: {
    // 初始化
    init() {
      this.element = {
        nav_item: document.getElementsByClassName("nav_item"),
        right_item: document.getElementsByClassName("rightItem"),
      };
      this.element.nav_item[0].classList.add("colorActive");
      window.addEventListener("scroll", this.rightScrollEventListener);
    },
    // 页面滚动监听,定位导航
    rightScrollEventListener() {
      const { nav_item, right_item } = this.element;
      let window_scrollTop =
        document.scrollTop || document.documentElement.scrollTop;
      // eslint-disable-next-line no-unused-vars
      const con = document.getElementsByClassName("con")[0].offsetTop;

      for (let i = 0; i < right_item.length; i++) {
        if (window_scrollTop >= right_item[0].offsetTop + 100) {
          document.getElementsByClassName("left")[0].classList.add("leftFixed");
        } else {
          document
            .getElementsByClassName("left")[0]
            .classList.remove("leftFixed");
        }
        let item_offsetTop = right_item[i].offsetTop;

        if (window_scrollTop >= item_offsetTop) {
          for (let n = 0; n < nav_item.length; n++) {
            const current_classList = nav_item[n].classList;
            if (i === n) {
              current_classList.add("colorActive");
              this.index = n;
            } else {
              current_classList.remove("colorActive");
            }
          }
        }
      }
    },
    // 楼层导航驱动方法
    selectItem(i) {
      const { right_item } = this.element;
      let rightItem_offsetTop = right_item[i].offsetTop + 50;
      let window_scrollTop =
        document.scrollTop || document.documentElement.scrollTop;
      let timer = {
        step: 50,
        times: 10,
        rightItem_offsetTop,
      };
      if (window_scrollTop < rightItem_offsetTop) {
        this.setScrollArrowUp(timer);
      } else if (window_scrollTop == rightItem_offsetTop) {
        return false;
      } else {
        this.setScrollArrowDown(timer);
      }
    },
    // 设置页面滚动
    setScrollArrowUp(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop <= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop - v.step;
          document.body.scrollTop = window_scrollTop - v.step;
        }
      }, v.times);
    },
    // // 设置页面向下滚动
    setScrollArrowDown(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop >= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop + v.step;
          document.body.scrollTop = window_scrollTop + v.step;
        }
      }, v.times);
    },
  },
};
</script>

<style lang="scss" scoped>
.care {
  .img1 {
    width: 100%;
    height: 300px;
    display: block;
  }
  .con {
    .left {
      width: 198px;
    }
    .nav_item {
      color: #3e3a39;
      width: 198px;
      position: relative;
      :hover {
        cursor: pointer;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 47px;
        height: 46px;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        text-align: center;
      }
      .icon {
        display: block;
        width: 198px;
        height: 47px;
        position: absolute;
        left: 0;
        top: 0;
      }
      .fcolor {
        color: #c8a062;
      }
    }
    .right {
      padding: 89px 360px 60px 666px;
      .con_title {
        width: 100%;
        height: 27px;
        border-bottom: 1px solid #e7e7e7;
        border-top: 1px solid #e7e7e7;
        border-right: 1px solid #e7e7e7;
        border-left: 1px solid #e7e7e7;
        position: relative;
        span {
          display: block;
          position: absolute;
          left: 0px;
          top: -20px;
          background: #ffffff;
          font-size: 32px;
          line-height: 32px;
          font-weight: 400;
          color: #3e3a39;
          width: 200px;
          padding-left: 30px;
          box-sizing: border-box;
        }
      }
      .img2 {
        width: 100%;
        height: 294px;
        margin-top: 47px;
        margin-bottom: 14px;
      }
      .img3 {
        width: 100%;
        height: 257px;
        margin-top: -28px;
      }
      .img4 {
        width: 100%;
        height: 303px;
      }
      .img5 {
        width: 100%;
        height: 214px;
      }
      .text {
        // font-size: 14px;
        text-align: justify;
        font-weight: 400;
        color: #3e3a39;
        line-height: 26px;
        margin: 30px 0 20px 0;
      }
      .title {
        font-size: 24px;
        font-weight: 400;
        color: #3e3a39;
        line-height: 24px;
      }
      .mt45 {
        margin-top: 45px;
      }
      .mb45 {
        margin-bottom: 45px;
      }

      .mt30 {
        margin-top: 30px;
      }
      .type2 {
        display: flex;
        direction: row;
        justify-content: space-between;
        .wh {
          width: 407px;
          margin-top: 70px;
        }
        .sy {
          width: 416px;
          margin-top: 65px;
        }
        .st {
          width: 461px;
          margin-top: 65px;
          margin-left: 20px;
        }
      }
      .type3 {
        margin: 90px 0 102px 0;
        .type3_con {
          display: flex;
          direction: row;
          justify-content: space-between;
          .type3_left {
            width: 430px;
          }
          .type3_right {
            width: 445px;
            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
        }
      }
      .type4 {
        position: relative;
        img {
          width: 100%;
          height: 261px;
          display: block;
        }
        .title {
          position: absolute;
          left: 0;
          top: 40px;
        }
      }
    }
  }
  .left {
    position: absolute;
    top: 80px;
    left: 360px;
  }
  .leftFixed {
    position: fixed !important;
    left: 360px !important;
    top: 140px !important;
  }
  .colorActive {
    color: #c8a062 !important;
  }
}
</style>
