<!--
 * @Author: your name
 * @Date: 2021-04-29 10:54:40
 * @LastEditTime: 2021-04-29 10:54:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /jkoffical-ui/src/views/lg/video/index.vue
-->
<template>
  <div>
    <NavBar :activeIndex="'/lg/lgVideo'" />
    <div class="title">
      <p class="text">
        精彩视频
      </p>
      <el-divider></el-divider>
    </div>
    <div class="video">
      <div class="videoCont">
        <el-row :gutter="12">
          <el-col
            :span="8"
            v-for="(item, index) in videoList"
            :key="index"
            class="item"
          >
            <a @click="goto(item.linkContent)">
              <img :src="item.imageUrl" alt="" />
            </a>
          </el-col>
        </el-row>
      </div>
      <div class="block">
        <div>共{{ total }}条新闻</div>
        <div class="right">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            @prev-click="handlePrev"
            @next-click="handleNext"
            :current-page.sync="currentPage"
            :page-size="5"
            layout="total, prev, pager, next"
            :page-count="pages"
            class="page"
          >
          </el-pagination>
          <div class="des">共{{ pages }}页</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/lgNavBar";
import Footer from "@/components/Footer";
export default {
  name: "vueName",
  data() {
    return {
      pages: 1,
      total: 0,
      currentPage: 1,
      loadingInstance: "",
      videoList: [],
    };
  },
  mounted() {
    this.getVedeoList();
  },
  methods: {
    async getVedeoList() {
      try {
        let res = await this.$axios.get(
          `/app/llg/video/page/${this.currentPage}/12`
        );
        if (res.status == 200 && res.data.code == 0) {
          this.videoList = res.data.data.list;
          this.total = res.data.data.totalCount;
          this.pages = res.data.data.totalPage;
          this.loadingInstance.close();
        } else {
          this.loadingInstance.close();
        }
      } catch (error) {
        this.loadingInstance.close();
        console.log(error);
      }
    },
    handleCurrentChange() {
      this.getNewsList();
    },
    handlePrev() {
      this.getNewsList();
    },
    handleNext() {
      this.getNewsList();
    },
    goto(url) {
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      a.click();
    },
  },
  created() {},
  components: {
    Footer,
    NavBar,
  },
};
</script>

<style scoped lang="scss">
.title {
  margin-top: 40px;
  overflow: hidden;
  .text {
    position: relative;
    width: 1200px;
    font-size: 34px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    line-height: 34px;
    color: #414e56;
    text-align: center;
    height: 40px;
    margin: 0px auto;
  }
  .el-divider {
    background-color: #80bc9b;
    margin: 0;
  }
}
.video {
  width: 1110px;
  margin: 10px auto;
  .videoCont {
    margin: 20px auto;
    .item {
      width: 350px;
      height: 264px;
      margin-bottom: 10px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .block {
    display: flex;
    direction: row;
    justify-content: space-between;
    margin: 28px 359px 57px 360px;
    line-height: 30px;
    font-size: 16px;
    color: #3e3a39;

    .right {
      display: flex;
      direction: row;
      .des {
        margin-left: 10px;
      }
    }
    .right
      ::v-deep
      .el-pagination.is-background
      .el-pager
      li:not(.disabled).active {
      background-color: #bf906c !important;
    }
    .right::v-deep
      .el-pagination.is-background
      .el-pager
      li:not(.disabled):hover {
      color: #bf906c !important;
    }
  }
}
</style>
