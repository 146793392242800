<template>
<div>
  <NavBar></NavBar>
  <div class="capital">
    <div class="capital_top_img"></div>
    <el-row class="capital_cont">
      <el-col class="left">
        <div
            class="nav_item"
            v-for="(item, i) in leftList"
            :key="i"
            @click.stop="selectItem(i)"
        >
          <p>{{ item.name }}</p>
          <img
              class="icon"
              v-if="i === index"
              src="../../assets/image/core/core-1-8.png"
              alt=""
          />
        </div>
      </el-col>
      <el-col class="right">
        <div>
          <div class="rightItem">
            <div class="con_title">
              <span>资本赋能</span>
            </div>
            <div class="desc">
              在产业孵化过程中，金恪注重发挥资本孵化和撬动作用，积极在产业创新发展领域配置金融工具，通过产业投资、金融服务、上市并购等手段发现战略投资新机会，放大资本赋能产业功能，不仅实现自身产业的快速发展，更达成了与合作伙伴的共赢创富，资本增值也成为了金恪集团价值增长的新引擎。
            </div>
            <div class="desc_details">
              <img src="../../assets/image/core/capital/img1.png" alt="">
            </div>
          </div>
          <div class="rightItem">
            <p class="title">金恪资本赋能增值体系</p>
            <div class="capital_desc_img img1" style="margin: 58px 0">
              <img src="../../assets/image/core/capital/img2.png" alt="">
            </div>
          </div>
          <div class="rightItem">
            <p class="title">赋能式投资并购</p>
            <div class="capital_desc_img img2" style="margin: 58px 0">
              <img src="../../assets/image/core/capital/img3.png" alt="">
            </div>
          </div>
          <div class="rightItem">
            <p class="title">上市并购</p>
            <p class="capital_desc">
              集团将各核心板块的主要业务独立分拆上市后，以上市公司平台为基础再展开产业链并购重组，建立上市公司主营业务的核心竞争力及产业护城河，以上市公司为平台展开产业链并购和融资等全面资本运作。            </p>
            <div class="capital_desc_img img2" style="margin: 58px 0">
              <img src="../../assets/image/core/capital/img4.png" alt="">
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  name: "capital",
  data(){
    return{
      leftList: [
        { name: "资本赋能", id: 0 },
        { name: "金恪资本赋能增值体系", id: 1 },
        { name: "赋能式投资并购", id: 2 },
        { name: "上市并购", id: 3 }
      ],
      index: 0,
      element: {
        nav_item: null,
        right_item: null,
      },
      timer: null,
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.rightScrollEventListener);
  },
  methods:{
    // 初始化
    init() {
      this.element = {
        nav_item: document.getElementsByClassName("nav_item"),
        right_item: document.getElementsByClassName("rightItem"),
      };
      this.element.nav_item[0].classList.add("colorActive");
      window.addEventListener("scroll", this.rightScrollEventListener);
    },
    // 页面滚动监听,定位导航
    rightScrollEventListener() {
      const { nav_item, right_item } = this.element;
      let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
      // const con = document.getElementsByClassName('con')[0].offsetTop

      for (let i = 0; i < right_item.length; i++) {
        if (window_scrollTop >= right_item[0].offsetTop + 100) {
          document.getElementsByClassName("left")[0].classList.add("leftFixed");
        } else {
          document
              .getElementsByClassName("left")[0]
              .classList.remove("leftFixed");
        } 
        let item_offsetTop = right_item[i].offsetTop + 20 * i;

        if (window_scrollTop >= item_offsetTop) {
          for (let n = 0; n < nav_item.length; n++) {
            const current_classList = nav_item[n].classList;
            if (i === n) {
              current_classList.add("colorActive");
              this.index = n;
            } else {
              current_classList.remove("colorActive");
            }
          }
        }
      }
    },
    // 楼层导航驱动方法
    selectItem(i) {
      const { right_item } = this.element;
      let rightItem_offsetTop = right_item[i].offsetTop + 50;
      let window_scrollTop = document.scrollTop || document.documentElement.scrollTop;
      let timer = {
        step: 50,
        times: 10,
        rightItem_offsetTop,
      };
      if (window_scrollTop < rightItem_offsetTop) {
        this.setScrollArrowUp(timer);
      } else if (window_scrollTop === rightItem_offsetTop) {
        return false;
      } else {
        this.setScrollArrowDown(timer);
      }
    },
    // 设置页面滚动
    setScrollArrowUp(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
            document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop <= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop - v.step;
          document.body.scrollTop = window_scrollTop - v.step;
        }
      }, v.times);
    },
    //设置页面向下滚动
    setScrollArrowDown(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
            document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop >= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop + v.step;
          document.body.scrollTop = window_scrollTop + v.step;
        }
      }, v.times);
    },
  },
  components: {
    Footer,
    NavBar,
  },
}
</script>

<style lang="scss" scoped>
.capital{
  box-sizing: border-box;
  .capital_top_img{
    height: 354px;
    width: 100%;
    background-image: url("../../assets/image/core/capital/top_img.png");
    background-size: 100%, 100%;
    background-repeat: no-repeat;
  }
  .capital_cont{
    .left{
      width: 198px;
    }
    .right{
      padding: 89px 360px 0 666px;
      .con_title {
        width: 100%;
        height: 27px;
        border-bottom: 1px solid #c8a062;
        border-top: 1px solid #c8a062;
        border-right: 1px solid #c8a062;
        border-left: 1px solid #c8a062;
        position: relative;
        margin-bottom: 20px;
        span {
          display: block;
          position: absolute;
          left: 0px;
          top: -20px;
          background: #ffffff;
          font-size: 32px;
          line-height: 32px;
          font-weight: 400;
          color: #c8a062;
          width: 200px;
          padding-left: 30px;
          box-sizing: border-box;
        }
      }
      .desc{
        font-weight: 400;
        font-size: 16px;
        color: #3F3A39;
        line-height: 28px;
      }
      .desc_details{
        width: 100%;
        height: 275px;
        margin-top: 24px;
        img{
          width: 100%;
        }
      }
      .rightItem{
        .title{
          margin-top: 52px;
          font-weight: 400;
          font-size: 24px;
          color: #525457;
        }
        .capital_desc{
          margin: 24px 0;
          font-weight: 400;
          font-size: 16px;
          color: #3F3A39;
          line-height: 28px;
        }
        .capital_desc_img{
          .img1{
            height: 374px;
          }
          .img2{
            height: 415px;
          }
          img{
            width: 100%;
          }
        }
      }
    }
    .nav_item {
      color: #3e3a39;
      width: 198px;
      position: relative;
      :hover {
        cursor: pointer;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 47px;
        height: 46px;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        text-align: center;
      }
      .icon {
        display: block;
        width: 198px;
        height: 47px;
        position: absolute;
        left: 0;
        top: 0;
      }
      .fcolor {
        color: #c8a062;
      }
    }
  }

  .left {
    position: absolute;
    top: 80px;
    left: 360px;
  }
  .leftFixed {
    position: fixed !important;
    left: 360px !important;
    top: 140px !important;
  }
  .colorActive {
    color: #c8a062 !important;
  }
  .rt {
    margin-right: 75px;
  }
}
</style>