import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/views/index.vue'
import Introduction from '@/views/about/introduction.vue'
import Business from '@/views/about/business.vue'
import Development from '@/views/about/development.vue'
import Management from '@/views/about/management'
import JkSixWay from '@/views/about/jkSixWay'
import News from '@/views/news/index.vue'
import NewsDetail from '@/views/news/detail.vue'
import Happiness from '@/views/core/happiness'
import Consume from '@/views/core/consume'
import Science from '@/views/core/science'
import Capital from '@/views/core/capital'
import GreenDevelopment from '@/views/core/greenDevelopment'
import incubation from '@/views/incubation/index.vue'
import ntHeart from '@/views/incubation/ntHeart.vue'
import service from '@/views/incubation/service.vue'
import technology from '@/views/incubation/technology.vue'
import trading from '@/views/incubation/trading.vue'
import empower from '@/views/empower/index.vue'
import financial from '@/views/empower/financial.vue'
import investment from '@/views/empower/investment.vue'
import marketing from '@/views/empower/marketing.vue'
import Declaration from "@/views/declaration/index.vue";
// import partner from '../views/jkCulture/partner.vue'
// import sixWays from '../views/jkCulture/sixWays.vue'
import qualification from '../views/qualification.vue'
import brand from '../views/brand/index'
import duty from '../views/duty/index'
import honor from "@/views/honor";
import cooperation from '../views/cooperation.vue'
import Home from "../views/lg/home"
import lgNews from "../views/lg/news/news.vue"
import lgNewsDetails from "../views/lg/news/newDetails.vue"
import lgVideo from "../views/lg/video/index.vue"
import newAbout from "../views/newAbout.vue"
Vue.use(Router)

export default new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes:[
    {
      path: '/',
      name: 'index',
      component: Index,
    },
    {
      path: '/about',
      name: 'about',
      component: Introduction,
    },
    {
      path:'/about/management',
      name:'management',
      component:Management,
    },
    {
      path:'/about/development',
      name:'development',
      component:Development,
    },
    {
      path:'/about/business',
      name:'business',
      component:Business,
    },
    {
      path:'/about/jkSixWay',
      name:'jkSixWay',
      component:JkSixWay,
    },
    {
      path:'/news/index',
      name:'news',
      component:News,
    },
    {
      path:'/news/detail',
      name:'detail',
      component:NewsDetail,
    },
    {
      path:'/core/happiness',
      name:'happiness',
      component:Happiness,
    },
    {
      path:'/core/consume',
      name:'consume',
      component:Consume,
    },
    {
      path:'/core/greenDevelopment',
      name:'greenDevelopment',
      component:GreenDevelopment,
    },
    {
      path:'/core/science',
      name:'science',
      component:Science,
    },
    {
      path:'/core/capital',
      name:'capital',
      component:Capital,
    },
    {
      path:'/incubation/index',
      name:'incubation',
      component:incubation,
    },
    {
      path:'/incubation/ntHeart',
      name:'ntHeart',
      component:ntHeart,
    },
    {
      path:'/incubation/service',
      name:'service',
      component:service,
    },
    {
      path:'/incubation/technology',
      name:'technology',
      component:technology,
    },
    {
      path:'/incubation/trading',
      name:'trading',
      component:trading,
    },
    {
      path:'/empower/index',
      name:'empower',
      component:empower,
    },
    {
      path:'/empower/financial',
      name:'financial',
      component:financial,
    },
    {
      path:'/empower/investment',
      name:'investment',
      component:investment,
    },
    {
      path:'/empower/marketing',
      name:'marketing',
      component:marketing,
    },
    // {
    //   path:'/jkCulture/sixWays',
    //   name:'sixWays',
    //   component:sixWays,
    // },
    // {
    //   path:'/jkCulture/partner',
    //   name:'partner',
    //   component:partner,
    // },
    {
      path:'/qualification',
      name:'qualification',
      component:qualification,
    },
    {
      path:'/brand',
      name:'brand',
      component:brand,
    },
    {
      path:'/duty',
      name:'duty',
      component:duty,
    },
    {
      path:'/honor',
      name:'honor',
      component:honor,
    },
    {
      path:'/cooperation',
      name:'cooperation',
      component:cooperation,
    },
    {
      path: '/lg/home',
      name: 'home',
      component:Home
    },
    {
      path: '/lg/shoot',
      name: 'home',
      component:Home
    },
    {
      path: '/lg/product',
      name: 'home',
      component:Home
    },
    {
      path: '/lg/lgNews',
      name: 'lgNews',
      component:lgNews
    },
    {
      path: '/lg/lgVideo',
      name: 'lgVideo',
      component:lgVideo
    },
    {
      path: '/lg/lgNewsDetails/:id',
      name: 'lgNewsDetails',
      component:lgNewsDetails
    },
    {
      path: '/newAbout',
      name: 'newAbout',
      component:newAbout
    },
    {
      path: '/declaration',
      name: 'declaration',
      component:Declaration
    }
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})
