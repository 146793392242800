<template>
  <!-- 金恪六道 -->
  <div>
    <NavBar></NavBar>
    <div class="duty">
      <img
          class="img1"
          src="../../assets/image/duty/top_img.png"
          alt=""
      />
      <div class="con">
        <div class="con_title">
          <span>社会责任</span>
        </div>
        <div class="text">{{ text }}</div>
        <div class="icon">
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/duty/icon1.png"
                alt=""
            />
            <p class="dec">金恪集团捐赠人民币500万元设立上海弘毅生态保护基金会，促进生态环保的建设。</p>
          </div>
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/duty/icon2.png"
                alt=""
            />
            <p class="dec">金恪集团捐赠300万元人民币，发起设立上海薪火相传传统文化保护基金会以开展保护与弘扬传统文化。</p>
          </div>
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/duty/icon3.png"
                alt=""
            />
            <p class="dec">金恪集团向海南弘毅扶贫慈善基金会捐赠1000余万元人民币，用于关爱儿童、老人以及发展教育事业。</p>
          </div>
        </div>
       <div class="duty_item">
         <div class="list item1">
           <p class="desc">金格集团在“携手兴乡村，百企结百村”中，结对帮扶新疆、西藏、云南等5个地区。</p>
         </div>
         <div class="list item2">
           <p class="desc">向上海市慈善基金会捐赠112万余元，驰援河南防汛救灾。</p>
         </div>
       </div>
        <div class="duty_item">
          <div class="list item3">
            <p class="desc">保护千年胡杨林行动 <br>古榧子孙树守护计划</p>
          </div>
          <div class="list item4">
            <p class="desc">向中国国际文化交流基金会捐赠200万元用于一带一路沿线文化建设事业。</p>
          </div>
        </div>
        <div class="duty_item">
          <div class="list item5">
            <p class="desc pt">疫情爆发初期，向华山医院捐赠价值约500余万元的物资；2020-2022 年期间，累计捐赠抗疫物资2000余万元。</p>
          </div>
          <div class="list item6">
            <p class="desc pt">捐助100万元用于对革命烈士、因公牺牲公安民（辅）警的家属，因公伤残公安民（辅）警，特困公安民（辅）警家庭给予抚助和从事公安民警抚恤等活动。</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  data() {
    return {
      text: `金恪将社会责任纳入整体战略，致力于用公益的心态做企业，用企业的形态做公益，以服务社会为基本准则，积极投身社会公益事业，重点在“扶助创业”“抗疫救灾”“生态保护”“爱心敬老”“文化交流”等领域系统规划并长期投入，以实际行动履行社会责任，助力实现共同富裕。`,
    };
  },
  components: {
    Footer,
    NavBar,
  },
};
</script>

<style lang="scss" scoped>
.duty {
  .img1 {
    width: 100%;
    height: 353px;
    display: block;
  }
  .con {
    padding: 60px 410px 43px 410px;
    .con_title {
      width: 1098px;
      height: 27px;
      border-bottom: 1px solid #c8996c;
      border-top: 1px solid #c8996c;
      border-right: 1px solid #c8996c;
      position: relative;
      span {
        display: block;
        position: absolute;
        left: 0px;
        top: -20px;
        background: #ffffff;
        font-size: 32px;
        line-height: 32px;
        font-weight: 400;
        color: #c8996c;
      }
    }
    .text {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      color: #3e3a39;
      margin: 20px 0 58px 0;
    }
    .icon {
      display: flex;
      justify-content: space-around;
      margin-bottom: 48px;
      .item {
        .img3 {
          width: 305px;
          height: 147px;
          display: block;
          margin: 0 auto;
        }
        .dec {
          margin-top: 30px;
          width: 305px;
          font-size: 20px;
          font-weight: 400;
          color: #3e3a39;
          line-height: 30px;
        }
      }
    }
    .duty_item{
      display: flex;
      justify-content: space-around;
      margin-bottom: 28px;
      .list{
        width: 540px;
        height: 175px;
        background-size: 100%, 100%;
        background-repeat: no-repeat;
      }
      .desc{
        width: 252px;
        float: right;
        font-weight: 400;
        font-size: 16px;
        color: #3F3A39;
        padding-top:50px ;
        padding-right: 30px;
        line-height: 28px;
      }
      .pt{
        padding-top: 15px;
      }
      .item1{
        background-image: url("../../assets/image/duty/item1.png");
      }
      .item2{
        background-image: url("../../assets/image/duty/item2.png");
      }
      .item3{
        background-image: url("../../assets/image/duty/item3.png");
      }
      .item4{
        background-image: url("../../assets/image/duty/item4.png");
      }
      .item5{
        background-image: url("../../assets/image/duty/item5.png");
      }
      .item6{
        background-image: url("../../assets/image/duty/item6.png");
      }

    }
  }
}
</style>
