<template>
<div>
  <NavBar></NavBar>
  <div class="jkSixWay">
    <div class="jkSixWay_top_img"></div>
    <div class="jkSixWay_cont">
      <div class="jkSixWay_details">
        <div class="item">
          <div class="item_icon">
            <img src="../../assets/image/about/jkSixWay/icon1.png" alt="">
          </div>
          <p class="title">六道文化</p>
          <div class="cont">
            <div class="cont_item"></div>
            <div class="cont_item">
              <p class="desc">行远自迩</p>
              <p class="desc">以人为本</p>
              <p class="desc">抱诚守真</p>
            </div>
            <div class="cont_item"></div>
            <div class="cont_item">
              <p class="desc">古道热肠</p>
              <p class="desc">匠心独运</p>
              <p class="desc">敦本务实</p>
            </div>
            <div class="cont_item"></div>
          </div>
        </div>
        <div class="item">
          <div class="item_icon">
            <img src="../../assets/image/about/jkSixWay/icon2.png" alt="">
          </div>
          <p class="title">企业使命</p>
          <div style="overflow: hidden">
            <p class="desc">打造产业增值体系</p>
            <p class="desc">共建人民幸福生活</p>
          </div>
        </div>
        <div class="item">
          <div class="item_icon">
            <img src="../../assets/image/about/jkSixWay/icon3.png" alt="">
          </div>
          <p class="title">企业愿景</p>
          <div style="overflow: hidden">
            <p class="desc">成为受人尊敬的</p>
            <p class="desc">价值增长与价值分享创新型企业</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  name: "jkSixWay",
  components: {
    Footer,
    NavBar,
  },
}
</script>

<style lang="scss" scoped>
.jkSixWay{
  .jkSixWay_top_img{
    height: 354px;
    width: 100%;
    background-image: url("../../assets/image/about/jkSixWay/top.png");
    background-size: 100%, 100%;
    background-repeat: no-repeat;
  }
  .jkSixWay_cont{
    width: 1100px;
    margin: 75px auto;
    .jkSixWay_details{
      display: flex;
      justify-content: space-between;
      justify-items: center;
      justify-self: center;
      .item{
        width: 344px;
        height: 600px;
        background-color: #eeeeee;
        .item_icon{
          width: 80px;
          height: 80px;
          margin: 60px auto 0 auto;
          img{
            width: 100%;
          }
        }
        .title{
          font-weight: 400;
          font-size: 34px;
          color: #D0A16C;
          text-align: center;
          margin: 16px 0;
          line-height: 34px;
        }
        .desc{
          text-align: center;
          margin: 16px auto;
          color: rgba(63, 58, 57, 1);
          font-weight: 400;
          font-family: 'MicrosoftYaHei',serif;
          font-size: 16px;
          line-height: 28px;
        }
        .cont{
          display: flex;
          justify-content: center;
          justify-items: center;
          justify-self: center;
          .cont_item{
            width: 20%;
          }
        }
      }
    }
  }
}
</style>