<template>
  <!-- 智慧科技 -->
  <div>
    <NavBar></NavBar>
    <div class="technology">
      <img
        class="img1"
        src="../../assets/image/incubation/incubation-1-1.png"
        alt=""
      />
      <el-row class="con">
        <el-col class="left">
          <div
            class="nav_item"
            v-for="(item, i) in leftList"
            :key="i"
            @click.stop="selectItem(i)"
          >
            <p>{{ item.name }}</p>
            <img
              class="icon"
              v-if="i == index"
              src="../../assets/image/core/core-1-8.png"
              alt=""
            />
          </div>
        </el-col>
        <el-col class="right">
          <div class="rightItem">
            <div class="con_title">
              <span>智慧科技</span>
            </div>
            <img
              class="img3"
              src="../../assets/image/incubation/incubation-2-1.png"
              alt=""
            />
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text1 }}
            </div>
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text2 }}
            </div>
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text3 }}
            </div>
          </div>
          <div class="type rightItem">
            <div class="title">金恪大数据</div>
            <img
              class="img4"
              src="../../assets/image/incubation/incubation-2-2.png"
              alt=""
            />
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text4 }}
            </div>
          </div>
          <div class="type mt rightItem">
            <div class="title">望潮科技</div>
            <img
              class="img4"
              src="../../assets/image/incubation/incubation-2-3.png"
              alt=""
            />
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text5 }}
            </div>
          </div>
          <div class="type mt rightItem">
            <div class="title">落地案例</div>
            <div class="title">
              与上海交投、久事集团合作建设上海新能源公交大数据管理平台
            </div>
            <div class="text" style="font-size:14px;line-height:26px">
              {{ text6 }}
            </div>
            <img
              class="img5"
              src="../../assets/image/incubation/incubation-2-4.png"
              alt=""
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  data() {
    return {
      text1: `金恪智慧科技产业依托“医、食、住、行、养、娱”六大领域，以大数据技术为内核，运用人工智能、物联网、区块链等技术，助力产业数字化、信息化的升级。基于对幸福通平台数据化运营，金恪智慧科技将用户数据、消费数据、商品数据、物流数据跨领域统一整合，通过数据模型，实现用户个性化需求高效匹配、产业服务及时互通。`,
      text2: `基于产业发展洞察与集团产业场景数据分析，智慧科技产业不断汲取科技行业先进经验，与高校、研究机构、投资机构形成紧密的合作关系，将海内外先进高尖端技术与人才进行引进，助力产业技术变革，构建智慧、分享、共赢的高科技生态平台。 `,
      text3: `截至目前，智慧科技产业不仅服务于金恪内部产业生态，也服务于多家政府机构及外部企业，通过自身技术积累，为合作伙伴提供高附加值的智慧城市服务及解决方案，致力成为新经济产业数据运营商。`,
      text4: `金恪大数据致力于推动大数据解决方案，为客户提供从数据采集、分析、挖掘、模型搭建到分析系统开发的多元化商业智能解决方案。依托于强大的数据基础、人工智能算法及行业理解，为零售企业、互联网公司、金融企业及政府机构，提供一体化云平台和定制服务，帮助企业决策和实现数字化升级。`,
      text5: `望潮科技是专注于前沿科技的新商业媒体和创新服务平台，致力于链接全球“科技”和“产业”，为用户提供海内外资本及人才对接、科技资讯、科创峰会、科技项目库等服务，推动前沿技术的产业落地，促进新商业的发展。`,
      text6: `金恪大数据利用丰富的行业经验和技术开发能力，通过对新能源公交运营场景进行梳理，将公交充电情况、场站利用率、车辆SOC等多维度数据进行解析，为政府、企业提供运营监控、运营分析支持。`,
      leftList: [
        { name: "智慧科技", id: 0 },
        { name: "金恪大数据", id: 1 },
        { name: "望潮科技", id: 2 },
      ],
      index: 0,
      element: {
        nav_item: null,
        right_item: null,
      },
      timer: null,
    };
  },
  components: {
    Footer,
    NavBar,
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.rightScrollEventListener);
  },
  methods: {
    // 初始化
    init() {
      this.element = {
        nav_item: document.getElementsByClassName("nav_item"),
        right_item: document.getElementsByClassName("rightItem"),
      };
      this.element.nav_item[0].classList.add("colorActive");
      window.addEventListener("scroll", this.rightScrollEventListener);
    },
    // 页面滚动监听,定位导航
    rightScrollEventListener() {
      const { nav_item, right_item } = this.element;
      let window_scrollTop =
        document.scrollTop || document.documentElement.scrollTop;
      // eslint-disable-next-line no-unused-vars
      const con = document.getElementsByClassName("con")[0].offsetTop;

      for (let i = 0; i < right_item.length; i++) {
        if (window_scrollTop >= right_item[0].offsetTop + 100) {
          document.getElementsByClassName("left")[0].classList.add("leftFixed");
        } else {
          document
            .getElementsByClassName("left")[0]
            .classList.remove("leftFixed");
        }
        let item_offsetTop = right_item[i].offsetTop + 20 * i;

        if (window_scrollTop >= item_offsetTop) {
          for (let n = 0; n < nav_item.length; n++) {
            const current_classList = nav_item[n].classList;
            if (i === n) {
              current_classList.add("colorActive");
              this.index = n;
            } else {
              current_classList.remove("colorActive");
            }
          }
        }
      }
    },
    // 楼层导航驱动方法
    selectItem(i) {
      // this.index = i
      // this.element.nav_item[i].classList.add('colorActive')
      const { right_item } = this.element;
      // const con = document.getElementsByClassName('con')[0].offsetTop
      let rightItem_offsetTop = right_item[i].offsetTop + 50;
      let window_scrollTop =
        document.scrollTop || document.documentElement.scrollTop;
      let timer = {
        step: 50,
        times: 10,
        rightItem_offsetTop,
      };
      if (window_scrollTop < rightItem_offsetTop) {
        this.setScrollArrowUp(timer);
      } else if (window_scrollTop == rightItem_offsetTop) {
        return false;
      } else {
        this.setScrollArrowDown(timer);
      }
    },
    // 设置页面滚动
    setScrollArrowUp(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop <= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop - v.step;
          document.body.scrollTop = window_scrollTop - v.step;
        }
      }, v.times);
    },
    // // 设置页面向下滚动
    setScrollArrowDown(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop >= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop + v.step;
          document.body.scrollTop = window_scrollTop + v.step;
        }
      }, v.times);
    },
  },
};
</script>

<style lang="scss" scoped>
.technology {
  .img1 {
    width: 100%;
    height: 300px;
    display: block;
  }
  .con {
    .left {
      width: 198px;
    }
    .nav_item {
      color: #3e3a39;
      width: 198px;
      position: relative;
      :hover {
        cursor: pointer;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 47px;
        height: 46px;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        text-align: center;
      }
      .icon {
        display: block;
        width: 198px;
        height: 47px;
        position: absolute;
        left: 0;
        top: 0;
      }
      .fcolor {
        color: #c8a062;
      }
    }
    .right {
      padding: 89px 360px 44px 666px;
      .con_title {
        width: 100%;
        height: 27px;
        border-bottom: 1px solid #e7e7e7;
        border-top: 1px solid #e7e7e7;
        border-right: 1px solid #e7e7e7;
        border-left: 1px solid #e7e7e7;
        position: relative;
        margin-bottom: 20px;
        span {
          display: block;
          position: absolute;
          left: 0px;
          top: -20px;
          background: #ffffff;
          font-size: 32px;
          line-height: 32px;
          font-weight: 400;
          color: #3e3a39;
          width: 200px;
          padding-left: 30px;
          box-sizing: border-box;
        }
      }
      .img2 {
        width: 100%;
        height: 252px;
        margin: 58px 0 28px 0;
      }
      .text {
        // font-size: 14px;
        text-align: justify;
        font-weight: 400;
        color: #3e3a39;
        line-height: 26px;
        margin-bottom: 20px;
      }
      .img3 {
        height: 354px;
        width: 100%;
      }
      .title {
        font-size: 24px;
        font-weight: 400;
        color: #3e3a39;
        line-height: 30px;
      }
      .text {
        // font-size: 14px;
        text-align: justify;
        font-weight: 400;
        color: #3e3a39;
        line-height: 26px;
        margin: 20px 0 17px 0;
      }
      .img4 {
        width: 100%;
        height: 234px;
        margin-top: 19px;
      }
      .img5 {
        width: 100%;
        height: 422px;
        margin-top: 19px;
      }
      .type {
        margin-top: 58px;
        .text {
          margin: 29px 0 0 0;
        }
      }
      .mt {
        margin-top: 100px;
      }
    }
  }
  .left {
    position: absolute;
    top: 80px;
    left: 360px;
  }
  .leftFixed {
    position: fixed !important;
    left: 360px !important;
    top: 140px !important;
  }
  .colorActive {
    color: #c8a062 !important;
  }
}
</style>
