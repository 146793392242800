<template>
<div>
  <NavBar></NavBar>
  <div class="science">
    <div class="science_top_img"></div>
    <el-row class="science_cont">
      <el-col class="left">
        <div
            class="nav_item"
            v-for="(item, i) in leftList"
            :key="i"
            @click.stop="selectItem(i)"
        >
          <p>{{ item.name }}</p>
          <img
              class="icon"
              v-if="i === index"
              src="../../assets/image/core/core-1-8.png"
              alt=""
          />
        </div>
      </el-col>
      <el-col class="right">
        <div>
          <div class="rightItem">
            <div class="con_title">
              <span>科技创新</span>
            </div>
            <div class="desc">
              党的二十大报告中强调，加快实施创新驱动发展战略，完善科技创新体系。坚持创新在我国现代化建设全局中的核心地位。金恪认为，企业是技术创新的主体，也是创新链产业链融合的关键。<br><br>
              金恪集团依托多年产业运营及孵化培育经验，打造未来产业创新赋能平台——“上海金恪科技谷”，以上海金恪研发总部、科创小巨人孵化器为核心，通过集聚效应和示范带动，有效嫁接内外部资源、加快创新体系构建，为入驻企业提供一站式创新创业服务，以高尖技术驱动社会及城市创新发展。党的二十大报告中强调，加快实施创新驱动发展战略，完善科技创新体系。坚持创新在我国现代化建设全局中的核心地位。金恪认为，企业是技术创新的主体，也是创新链产业链融合的关键。
            </div>
            <div class="desc_details">
              <img src="../../assets/image/core/science/img1.png" alt="">
            </div>
          </div>
          <div class="rightItem">
            <p class="title">上海金恪科技谷</p>
            <div class="science_desc_img img1" style="margin: 58px 0">
              <img src="../../assets/image/core/science/img2.png" alt="">
            </div>
          </div>
          <div class="rightItem">
            <p class="title">科创小巨人孵化平台 · 产业驱动型科技投资与孵化生态</p>
            <p class="science_desc">
              依托人工智能、云计算、大数据、物联网等技术，打造涵盖元宇宙、智能终端、生物制药、数字经济、医养大健康等战略新兴产业集群，通过应用研发、股权投资、人才培育、创新基地、产业运营等产学研深度融合的科技创新服务体系，布局高层次的科创园区，形成产业创新生态圈。
            </p>
            <div class="science_desc_img img2" style="margin: 58px 0">
              <img src="../../assets/image/core/science/img3.png" alt="">
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  name: "science",
  data(){
    return{
      leftList: [
        { name: "科技创新", id: 0 },
        { name: "上海金恪科技谷", id: 1 },
        { name: "科创小巨人孵化平台", id: 2 }
      ],
      index: 0,
      element: {
        nav_item: null,
        right_item: null,
      },
      timer: null,
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.rightScrollEventListener);
  },
  methods:{
    // 初始化
    init() {
      this.element = {
        nav_item: document.getElementsByClassName("nav_item"),
        right_item: document.getElementsByClassName("rightItem"),
      };
      this.element.nav_item[0].classList.add("colorActive");
      window.addEventListener("scroll", this.rightScrollEventListener);
    },
    // 页面滚动监听,定位导航
    rightScrollEventListener() {
      const { nav_item, right_item } = this.element;
      let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
      // const con = document.getElementsByClassName('con')[0].offsetTop

      for (let i = 0; i < right_item.length; i++) {
        if (window_scrollTop >= right_item[0].offsetTop + 100) {
          document.getElementsByClassName("left")[0].classList.add("leftFixed");
        } else {
          document
              .getElementsByClassName("left")[0]
              .classList.remove("leftFixed");
        }
        let item_offsetTop = right_item[i].offsetTop + 20 * i;

        if (window_scrollTop >= item_offsetTop) {
          for (let n = 0; n < nav_item.length; n++) {
            const current_classList = nav_item[n].classList;
            if (i === n) {
              current_classList.add("colorActive");
              this.index = n;
            } else {
              current_classList.remove("colorActive");
            }
          }
        }
      }
    },
    // 楼层导航驱动方法
    selectItem(i) {
      const { right_item } = this.element;
      let rightItem_offsetTop = right_item[i].offsetTop + 50;
      let window_scrollTop = document.scrollTop || document.documentElement.scrollTop;
      let timer = {
        step: 50,
        times: 10,
        rightItem_offsetTop,
      };
      if (window_scrollTop < rightItem_offsetTop) {
        this.setScrollArrowUp(timer);
      } else if (window_scrollTop == rightItem_offsetTop) {
        return false;
      } else {
        this.setScrollArrowDown(timer);
      }
    },
    // 设置页面滚动
    setScrollArrowUp(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
            document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop <= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop - v.step;
          document.body.scrollTop = window_scrollTop - v.step;
        }
      }, v.times);
    },
    // // 设置页面向下滚动
    setScrollArrowDown(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
            document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop >= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop + v.step;
          document.body.scrollTop = window_scrollTop + v.step;
        }
      }, v.times);
    },
  },
  components: {
    Footer,
    NavBar,
  },
}
</script>

<style lang="scss" scoped>
.science{
  box-sizing: border-box;
  .science_top_img{
    height: 354px;
    width: 100%;
    background-image: url("../../assets/image/core/science/top_img.png");
    background-size: 100%, 100%;
    background-repeat: no-repeat;
  }
  .science_cont{
    .left{
      width: 198px;
    }
    .right{
      padding: 89px 360px 0 666px;
      .con_title {
        width: 100%;
        height: 27px;
        border-bottom: 1px solid #c8a062;
        border-top: 1px solid #c8a062;
        border-right: 1px solid #c8a062;
        border-left: 1px solid #c8a062;
        position: relative;
        margin-bottom: 20px;
        span {
          display: block;
          position: absolute;
          left: 0px;
          top: -20px;
          background: #ffffff;
          font-size: 32px;
          line-height: 32px;
          font-weight: 400;
          color: #c8a062;
          width: 200px;
          padding-left: 30px;
          box-sizing: border-box;
        }
      }
      .desc{
        font-weight: 400;
        font-size: 16px;
        color: #3F3A39;
        line-height: 28px;
      }
      .desc_details{
        width: 100%;
        height: 275px;
        margin-top: 24px;
        img{
          width: 100%;
        }
      }
      .rightItem{
        .title{
          margin-top: 52px;
          font-weight: 400;
          font-size: 24px;
          color: #525457;
        }
        .science_desc{
          margin: 24px 0;
          font-weight: 400;
          font-size: 16px;
          color: #3F3A39;
          line-height: 28px;
        }
        .science_desc_img{

          .img1{
            height: 370px;
          }
          .img2{
            height: 430px;
          }
          img{
            width: 100%;
          }
        }
      }
    }
    .nav_item {
      color: #3e3a39;
      width: 198px;
      position: relative;
      :hover {
        cursor: pointer;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 47px;
        height: 46px;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        text-align: center;
      }
      .icon {
        display: block;
        width: 198px;
        height: 47px;
        position: absolute;
        left: 0;
        top: 0;
      }
      .fcolor {
        color: #c8a062;
      }
    }
  }

  .left {
    position: absolute;
    top: 80px;
    left: 360px;
  }
  .leftFixed {
    position: fixed !important;
    left: 360px !important;
    top: 140px !important;
  }
  .colorActive {
    color: #c8a062 !important;
  }
  .rt {
    margin-right: 75px;
  }
}
</style>