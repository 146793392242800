<template>
<!-- 页脚 -->
  <el-row class="footerPage">
    <el-row class="row_one">
      <ul v-for="(item,i) in navTitle" :key="i" class="elcol">
        <li class="title"  @click="goDetail(item)">{{item.title}}</li>
        <li class="child" v-for="child in item.children" :key="child.id" @click="goDetail(child)">{{child.name}}</li>
        <div class="line" v-if="i==0 || i==1 || i == 2 || i == 3||i==4"></div>
      </ul>
    </el-row>
    <el-row type="flex" class="row_two" justify="space-between">
      <el-col :span="12">
        <div class="con1">版权所有<img class="icon" src="../assets/image/index/icon.png">2020金恪集团沪ICP备17027599号-3</div>
      </el-col>
      <el-col  :span="12">
        <div class="copyright">
          <ul>
            <li><a class="textName" href="https://www.yygroup.net/">艳阳度假</a></li>
            <li><a class="textName" href="https://www.jkjz.cn/">金恪建筑</a></li>
            <li><a class="textName" href="https://www.hansa-tec.com/">翰萨智能</a></li>
            <li><a class="textName" href="https://www.fusinpharmacy.cn/">福歆医药</a></li>
            <li><a class="textName" style="border: none" href="http://www.shikefood.com">食恪生鲜</a></li>
          </ul>
        </div>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import navlist from '../assets/js/navtitle'
  export default {
    data(){
      return {
        navTitle:navlist,
      }
    },
    methods: {
      goDetail(v){
        this.$router.push(v.path)
      }
    },
  }
</script>

<style lang="scss" scoped>
.footerPage{
  min-width: 1200px;
  ul,li{
    list-style: none;
  }
  .row_one{
    padding:28px 360px;
    background: rgb(51,51,51);
    display: flex;
    justify-content: space-between;
    height: 310px;
    .elcol{
      width: 172px;
      padding-left: 45px;
      box-sizing: border-box;
      position: relative;
      .line{
        position: absolute;
        top: 0%;
        right: 0;
        width: 1px;
        height: 21px;
        background:rgb(83,71,65);
      }
      .title{
        font-weight: bold;
        margin-bottom: 26px;
        cursor: pointer;
        color: #B6876C;
        font-size: 16px;
      }
      .child{
        color: #9D9E9E;
        margin-bottom: 24px;
        cursor: pointer;
        font-size:14px;
        :last-child{
          margin-bottom: 20px;
        }
      }
    }
  }
  .row_two{
    color: #B5866C;
    background: #3e3a39;
    height: 90px;
    line-height: 90px;
    padding:0 360px 0 390px;
    display: flex;
    font-weight: bold;

    .img1{
      display: inline-block;
      width: 18px;
      height: 18px;
    }
    .img2{
      display: inline-block;
      width: 18px;
      height: 16px;
    }
    .phone{
      display: inline-block;
      margin-left: 6px;
      margin-right: 15px;
    }
    .copyright{
       ul li{
         float: left;
         font-size: 14px;
         .textName{
           border-right: 2px solid #8f9090;
           color: #8f9090;
           padding: 0 10px;
           line-height: 14px;
         }
       }

    }
    .icon{
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-left: 10px;
    }
  }
  a{
    text-decoration: none;
  }
}
</style>
