<template>
  <!-- 金恪六道 -->
  <div>
    <NavBar></NavBar>
    <div class="sixWays">
      <img
          class="img1"
          src="../../assets/image/honor/top_img.png"
          alt=""
      />
      <div class="con">
        <div class="con_title">
          <span>企业荣誉</span>
        </div>
        <div class="icon">
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/honor/icon1.png"
                alt=""
            />
            <p class="dec">上海市贸易型企业总部</p>
          </div>
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/honor/icon2.png"
                alt=""
            />
            <p class="dec">上海市民营企业总部</p>
          </div>
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/honor/icon3.png"
                alt=""
            />
            <p class="dec">新华信用平台入驻企业</p>
          </div>
        </div>
        <div class="icon">
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/honor/icon4.png"
                alt=""
            />
            <p class="dec">中国企业公民责任品牌60强</p>
          </div>
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/honor/icon5.png"
                alt=""
            />
            <p class="dec">浦东开发开放30周年优秀企业</p>
          </div>
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/honor/icon6.png"
                alt=""
            />
            <p class="dec">浦东总部经济十大经典样本</p>
          </div>
        </div>
        <div class="icon">
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/honor/icon7.png"
                alt=""
            />
            <p class="dec">中国社会责任杰出企业</p>
          </div>
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/honor/icon8.png"
                alt=""
            />
            <p class="dec">浦东新区公益慈善奖</p>
          </div>
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/honor/icon9.png"
                alt=""
            />
            <p class="dec">中国品牌金信学院奖</p>
          </div>
        </div>
        <div class="icon">
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/honor/icon10.png"
                alt=""
            />
            <p class="dec">优秀商业模式创新奖</p>
          </div>
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/honor/icon11.png"
                alt=""
            />
            <p class="dec">援疆奉献奖</p>
          </div>
          <div class="item">
            <img
                class="img3"
                src="../../assets/image/honor/icon12.png"
                alt=""
            />
            <p class="dec">中国老龄产业协会<br>《机构养老服务 健康管理服务规范》<br>团体标准制定荣誉表彰</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  data() {
    return {
      text: `金恪文化早已成为金恪肌体中亘古流淌的一种精神，成为金恪永续前行的核心竞争力，金恪人以金恪六道修身，以社会责任为己任，以包容、开放、诚信的姿态，坚守内心的信仰，恪守金子般的承诺。`,
    };
  },
  components: {
    Footer,
    NavBar,
  },
};
</script>

<style lang="scss" scoped>
.sixWays {
  .img1 {
    width: 100%;
    height: 353px;
    display: block;
  }
  .con {
    padding: 60px 410px 43px 410px;
    .con_title {
      width: 1098px;
      height: 27px;
      border-bottom: 1px solid #c8996c;
      border-top: 1px solid #c8996c;
      border-right: 1px solid #c8996c;
      position: relative;
      span {
        display: block;
        position: absolute;
        left: 0px;
        top: -20px;
        background: #ffffff;
        font-size: 32px;
        line-height: 32px;
        font-weight: 400;
        color: #c8996c;
      }
    }
    .text {
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      color: #3e3a39;
      margin: 20px 0 58px 0;
    }
    .img2 {
      display: block;
      width: 100%;
      height: 344px;
    }
    .icon {
      display: flex;
      justify-content: space-between;
      margin:20px 0;
      .item {
        text-align: center;
        .img3 {
          width: 305px;
          height: 298px;
          display: block;
          margin: 0 auto;
        }

        .dec {
          font-size: 16px;
          font-weight: 400;
          color: #B2836D;
          line-height: 28px;
        }
      }
    }
  }
}
</style>
