<template>
  <!-- 幸福创业+ -->
  <div>
    <NavBar></NavBar>
    <div class="business">
      <img class="img1" src="../../assets/image/about/about4-1.png" alt="" />
      <div class="con">
        <div
          class="con_title"
          style="border-width: 0.5px 0.5px 0.5px 0;font-size:32px"
        >
          <span>幸福创业+</span>
        </div>
        <div class="text" style="font-size:14px;line-height:26px">
          {{ text1 }}
        </div>
        <div class="text text2" style="font-size:14px;line-height:26px">
          {{ text2 }}
        </div>
        <img class="img2" src="../../assets/image/about/about4-2.png" alt="" />
        <div class="img3">
          <img src="../../assets/image/about/about4-3.png" alt="" />
          <img
            class="img4"
            src="../../assets/image/about/about4-4.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  data() {
    return {
      text1: `金恪集团联合新华信用发布“幸福创业+”计划，以金恪多年的产业经验及资源优势助力地方政府、中小微企业、个人消费者全方位发展。`,
      text2: `金恪"幸福创业+"为"三类"群体，即：G（地方政府）、B（中小微企业）和C（个人消费者）提供产业及战略规划、产业发展基金、优质产品遴选及优质企业投资、营销体系赋能、供应链金融服务、电商直播红人培育等全方位服务。`,
    };
  },
  components: {
    Footer,
    NavBar,
  },
};
</script>

<style lang="scss" scoped>
.business {
  .img1 {
    width: 100%;
    height: 353px;
    display: block;
  }
  .con {
    padding: 60px 0 43px 415px;
    width: 1099px;
    .con_title {
      width: 100%;
      height: 27px;
      position: relative;
      border-color: #c8996c;
      border-style: solid;
      span {
        display: block;
        position: absolute;
        left: 0px;
        top: -20px;
        background: #ffffff;
        // font-size: 32px;
        line-height: 32px;
        font-weight: 400;
        color: #caa574;
      }
    }
    .text {
      // font-size: 14px;
      font-weight: 400;
      color: #3e3a39;
      // line-height: 26px;
      margin-top: 20px;
      text-align: justify;
    }
    .text2 {
      margin: 25px 0;
    }
    .img2 {
      display: block;
      width: 885px;
      height: 210px;
      margin-left: 83px;
    }
    .img3 {
      display: flex;
      direction: row;
      margin-top: 15px;
      margin-left: 83px;
      width: 885px;
      img {
        width: 50%;
        display: block;
      }
      .img4 {
        margin-left: 15px;
      }
    }
  }
}
</style>
