<template>
  <!-- 新闻动态主页 -->
  <div>
    <NavBar></NavBar>
    <div class="news">
      <div v-if="newsList.length > 0">
        <div class="list">
          <div class="item" v-for="(item, i) in newsList" :key="i">
            <img :src="item.imageUrl" alt="" />
            <div class="right">
              <div class="top">
                <p class="title">{{ item.title }}</p>
                <p class="time">{{ item.publishTime }}</p>
              </div>
              <div class="center" style="font-size:14px;line-height:26px">
                {{ item.descDetail }}
              </div>
              <div class="icon" @click="goNewsDetail(item)">MORE→</div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="block">
          <div>共{{ total }}条新闻</div>
          <div class="right">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              @prev-click="handlePrev"
              @next-click="handleNext"
              :current-page.sync="currentPage"
              :page-size="5"
              layout="total, prev, pager, next"
              :page-count="pages"
            >
            </el-pagination>
            <div class="des">共{{ pages }}页</div>
          </div>
        </div>
      </div>
      <div v-else class="empty">信息正在编辑中，请稍等~</div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      pages: 1,
      total: 0,
      currentPage: 1,
      newsList: [],
      height: {
        height: "",
      },
      loadingInstance: "",
    };
  },
  created() {
    this.loadingInstance = Loading.service({ fullscreen: true });
    this.getNewsList();
  },
  components: {
    Footer,
    NavBar,
  },
  methods: {
    // 获取新闻列表
    async getNewsList() {
      try {
        let res = await this.$axios.get(`/app/news/page/${this.currentPage}`);
        if (res.status == 200 && res.data.code == 0) {
          this.newsList = res.data.data.list;
          this.total = res.data.data.totalCount;
          this.pages = res.data.data.totalPage;
          this.loadingInstance.close();
        } else {
          this.loadingInstance.close();
          console.log(res.data.msg, "msg");
        }
      } catch (error) {
        this.loadingInstance.close();
        console.log(error);
      }
    },
    goNewsDetail(v) {
      this.$router.push(`/news/detail?id=${v.id}`);
    },
    handleCurrentChange() {
      this.getNewsList();
    },
    handlePrev() {
      this.getNewsList();
    },
    handleNext() {
      this.getNewsList();
    },
  },
};
</script>
<style lang="scss">
.news {
  .el-pager li {
    height: 30px !important;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #bf906c !important;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #bf906c !important;
  }
}
</style>

<style lang="scss" scoped>
.news {
  min-height: 100%;
  .list {
    padding: 46px 359px 0 360px;
    .item {
      display: flex;
      direction: row;
      justify-content: space-between;
      border-bottom: 1px dashed #000;
      padding-bottom: 37px;
      padding-top: 37px;
      img {
        width: 501px;
        height: 301px;
        display: block;
        margin-right: 36px;
      }
      .right {
        width: 664px;
        position: relative;
        .top {
          display: flex;
          direction: row;
          justify-content: space-between;
          border-bottom: 1px solid #c0916c;
          height: 60px;
          line-height: 60px;
          .title {
            font-size: 24px;
            color: #bf906c;
            width: 65%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .time {
            font-size: 16px;
            color: #bf906c;
            line-height: 80px;
          }
        }
        .icon {
          text-align: right;
          font-size: 14px;
          color: #bf906c;
          position: absolute;
          right: 0;
          bottom: 0;
          cursor: pointer;
        }
        .center {
          color: #3e3a39;
          // font-size: 14px;
          text-align: justify;
          line-height: 26px;
          padding-top: 20px;
          height: 85px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
      }
    }
  }
  .block {
    display: flex;
    direction: row;
    justify-content: space-between;
    margin: 28px 359px 57px 360px;
    line-height: 30px;
    font-size: 16px;
    color: #3e3a39;
    .right {
      display: flex;
      direction: row;
      .des {
        margin-left: 10px;
      }
    }
  }
  .empty {
    margin-top: 80px;
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    color: #3e3a39;
    text-align: center;
    height: 600px;
  }
}
</style>
