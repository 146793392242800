<template>
  <!-- 产业投资 -->
  <div>
    <NavBar></NavBar>
    <div class="investment">
      <img
          class="img1"
          src="../../assets/image/empower/empower-2-1.png"
          alt=""
      />
      <el-row class="con">
        <el-col class="left">
          <div
              class="nav_item"
              v-for="(item, i) in leftList"
              :key="i"
              @click.stop="selectItem(i)"
          >
            <p>{{ item.name }}</p>
            <img
                class="icon"
                v-if="i == index"
                src="../../assets/image/core/core-1-8.png"
                alt=""
            />
          </div>
        </el-col>
        <el-col class="right">
          <div class="rightItem">
            <div class="con_title">
              <span>产业投资</span>
            </div>
            <img
                class="img2"
                src="../../assets/image/empower/empower-2-2.png"
                alt=""
            />
            <div class="text" style="font-size:14px;line-height:26px;margin-bottom: 20px">
              作为现代实业投资集团，金恪通过全国各产业基地的多年实践，在助力乡村振兴、推动区域经济发展方面，已逐渐摸索出一套行之有效的“金恪方案”，即：通过一二三产融合及农旅养多产业相互配合引流、协同联动，来优化区域资源配置，并形成多元利益共同体。
            </div>
            <div class="top-title" style="font-size: 24px">打造国储林一二三产联动 政企农合作新样板</div>
            <div class="text"  style=" font-size: 16px;line-height: 28px;">对标油茶、油橄榄等国家优先支持的12种木本油料树种，山桐子具有更大的潜力和优势，是我国本土最理想的优质高产木本油料树种。</div>
            <div class="item">
              <div class="list">
                <p class="title" style=" font-size: 20px;  line-height: 20px;">“树上油库”黄金树助力实现国家粮油安全战略</p>
                <p class="desc" style=" font-size: 16px;line-height: 28px;">
                  中国当前面临食用油消费严重依赖进口的局面，山桐子被列入了国家支持大力发展木本油料的相关树种名单，国家卫生健康委员会官网显示，山桐子油位列普通食品管理审查目录，大力发展山桐子产业可有效提高食用油自给率，从根本上保障国家粮油安全。
                </p>
                <p class="title" style=" font-size: 20px;  line-height: 20px;">带动八大产业发展切实筑牢乡村振兴产业基石</p>
                <p class="desc" style=" font-size: 16px;line-height: 28px;">
                  山桐子国储林建设以林地为依托，带动发展木本油料、木材加工、有机肥饲料、生物医药、中医药材、生物质能源、森林康养、林业碳汇八大产业，实现全产业链增收，更大程度释放绿水青山的生态红利和经济红利。</p>
                <p class="title" style=" font-size: 20px;  line-height: 20px;">产生大量就业机会农民增收走向共同富裕之路</p>
                <p class="desc" style=" font-size: 16px;line-height: 28px;">
                  山桐子产业需要大量的劳动力，育苗、种植、采摘、加工、销售、服务等，能够提供众多的就业岗位，减少社会就业压力。同时，通过与当地农民合作种植、土地流转等方式，利益联营，切实带动更多村民奔向共同富裕。</p>
              </div>
              <div>
                <img src="../../assets/image/empower/empower-4-8.png" alt="">
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default {
  data() {
    return {
      leftList: [{name: "产业投资", id: 0}],
      index: 0,
      element: {
        nav_item: null,
        right_item: null,
      },
      timer: null,
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.rightScrollEventListener);
  },
  components: {
    Footer,
    NavBar,
  },
  methods: {
    // 初始化
    init() {
      this.element = {
        nav_item: document.getElementsByClassName("nav_item"),
        right_item: document.getElementsByClassName("rightItem"),
      };
      this.element.nav_item[0].classList.add("colorActive");
      window.addEventListener("scroll", this.rightScrollEventListener);
    },
    // 页面滚动监听,定位导航
    rightScrollEventListener() {
      const {nav_item, right_item} = this.element;
      let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
      // eslint-disable-next-line no-unused-vars
      const con = document.getElementsByClassName("con")[0].offsetTop;

      for (let i = 0; i < right_item.length; i++) {
        if (window_scrollTop >= right_item[0].offsetTop + 100) {
          document.getElementsByClassName("left")[0].classList.add("leftFixed");
        } else {
          document
              .getElementsByClassName("left")[0]
              .classList.remove("leftFixed");
        }
        let item_offsetTop = right_item[i].offsetTop + 20 * i;

        if (window_scrollTop >= item_offsetTop) {
          for (let n = 0; n < nav_item.length; n++) {
            const current_classList = nav_item[n].classList;
            if (i === n) {
              current_classList.add("colorActive");
              this.index = n;
            } else {
              current_classList.remove("colorActive");
            }
          }
        }
      }
    },
    // 楼层导航驱动方法
    selectItem(i) {
      const {right_item} = this.element;
      let rightItem_offsetTop = right_item[i].offsetTop + 50;
      let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
      let timer = {
        step: 50,
        times: 10,
        rightItem_offsetTop,
      };
      if (window_scrollTop < rightItem_offsetTop) {
        this.setScrollArrowUp(timer);
      } else if (window_scrollTop == rightItem_offsetTop) {
        return false;
      } else {
        this.setScrollArrowDown(timer);
      }
    },
    // 设置页面滚动
    setScrollArrowUp(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
            document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop <= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop - v.step;
          document.body.scrollTop = window_scrollTop - v.step;
        }
      }, v.times);
    },
    // // 设置页面向下滚动
    setScrollArrowDown(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
            document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop >= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop + v.step;
          document.body.scrollTop = window_scrollTop + v.step;
        }
      }, v.times);
    },
  },
};
</script>

<style lang="scss" scoped>
.investment {
  .img1 {
    width: 100%;
    height: 300px;
    display: block;
  }

  .con {
    .left {
      width: 198px;
    }

    .nav_item {
      color: #3e3a39;
      width: 198px;
      position: relative;

      :hover {
        cursor: pointer;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 47px;
        height: 46px;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        text-align: center;
      }

      .icon {
        display: block;
        width: 198px;
        height: 47px;
        position: absolute;
        left: 0;
        top: 0;
      }

      .fcolor {
        color: #c8a062;
      }
    }

    .right {
      padding: 89px 360px 50px 666px;

      .con_title {
        width: 100%;
        height: 27px;
        border-bottom: 1px solid #e7e7e7;
        border-top: 1px solid #e7e7e7;
        border-right: 1px solid #e7e7e7;
        border-left: 1px solid #e7e7e7;
        position: relative;

        span {
          display: block;
          position: absolute;
          left: 0px;
          top: -20px;
          background: #ffffff;
          font-size: 32px;
          line-height: 32px;
          font-weight: 400;
          color: #3e3a39;
          width: 200px;
          padding-left: 30px;
          box-sizing: border-box;
        }
      }

      .img2 {
        width: 100%;
      }

      .item_img {
        text-align: center;
        padding-top: 50px;
      }

      .text {
        // font-size: 14px;
        font-weight: 400;
        color: #3e3a39;
        line-height: 26px;
        margin-top: 20px;
        text-align: justify;
      }

      .title2 {
        color: #d2a36c;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        margin-top: 20px;
      }

      .title {
        font-size: 24px;
        font-weight: 400;
        color: #3e3a39;
        line-height: 22px;
      }

      .top-title {
        font-family: Microsoft YaHei, sans-serif;
        font-weight: 400;
        color: #525457;
        padding: 20px 0;
      }

      .item {
        display: flex;
        justify-content: space-between;

        .title {
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #525457;
          padding: 20px 0;
        }

        .list {
          max-width: 342px;
        }

        .desc {
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #3E3A39;

        }

        img {
          max-width: 535px;
        }
      }

      .mt80 {
        margin-top: 80px;
      }

      .mt60 {
        margin-top: 60px;
      }
    }
  }

  .left {
    position: absolute;
    top: 80px;
    left: 360px;
  }

  .leftFixed {
    position: fixed !important;
    left: 360px !important;
    top: 140px !important;
  }

  .colorActive {
    color: #c8a062 !important;
  }
}
</style>
