<template>
  <div>
    <NavBar></NavBar>
    <div class="manage">
      <div class="manage_top_img"></div>
      <div class="manage_cont">
        <div class="manage_cont_title" style="border-width: 1px 1px 1px 0;font-size:32px">
          <span>管理之道</span>
        </div>
        <div class="manage_desc">
          <div class="item">
            <div class="item_icon">
              <img class="icon" src="../../assets/image/about/manage/icon1.png" alt="">
              <span class="icon_title">员工创客化</span>
            </div>
          </div>
          <div class="item">
            <div class="item_icon">
              <img class="icon" src="../../assets/image/about/manage/icon2.png" alt="">
              <span class="icon_title">部门公司化</span>
            </div>
          </div>
          <div class="item">
            <div class="item_icon">
              <img class="icon" src="../../assets/image/about/manage/icon3.png" alt="">
              <span class="icon_title">公司平台化</span>
            </div>
          </div>
          <div class="item">
            <div class="item_icon">
              <img class="icon" src="../../assets/image/about/manage/icon4.png" alt="">
              <span class="icon_title">平台生态化</span>
            </div>
          </div>
        </div>
        <div class="partner">
          <p class="partner_title">金恪合伙人</p>
          <div class="partner_top"></div>
          <div class="partner_desc">
            <div class="desc">
              <p class="desc_name">员工合伙人</p>
              <p class="desc_num">300+</p>
            </div>
            <div class="desc">
              <p class="desc_name">企业合伙人</p>
              <p class="desc_num">160+</p>
            </div>
            <div class="desc">
              <p class="desc_name">政府合伙人</p>
              <p class="desc_num">40+</p>
            </div>
            <div class="desc">
              <p class="desc_name">战略合伙人</p>
              <p class="desc_num">30+</p>
            </div>
            <div class="desc">
              <p class="desc_name">创客事业部</p>
              <p class="desc_num">20+</p>
            </div>
            <div class="desc">
              <p class="desc_name">外部技术合伙人</p>
              <p class="desc_num">150+</p>
            </div>
          </div>
        </div>
        <div class="manage_details">
          <div class="details_item">
            <p class="item_title">能力封装</p>
            <div class="item_img">
              <img src="../../assets/image/about/manage/manage_img2.png" alt="">
            </div>
            <div class="details_desc">
              通过人工智能建立大中台组织，将商业模式运作中所需能力归类、分层，标准化，为员工及创业者提供标准“黑盒子”。
            </div>
          </div>
          <div class="details_item">
            <p class="item_title">基础下沉</p>
            <div class="item_img">
              <img src="../../assets/image/about/manage/manage_img3.png" alt="">
            </div>
            <div class="details_desc">
              金恪打破一切壁垒的生态平台打造方法，为金恪的合作伙伴、客户建设好行业“基础设施”，让事业伙伴在金恪的平台上进行建设发展而无后顾之忧。
            </div>
          </div>
          <div class="details_item">
            <p class="item_title">共生共存</p>
            <div class="item_img">
              <img src="../../assets/image/about/manage/manage_img4.png" alt="">
            </div>
            <div class="details_desc">
              金恪打造新的组织模式——去中心化的自组织，开放式的创业生态圈，将层级制逐渐转变为去中心化，以共同的理念、目标联合发展，共生共存。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--管理之道-->
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  name: "management",
  components: {
    Footer,
    NavBar,
  },
}
</script>

<style lang="scss" scoped>
.manage{
  .manage_top_img{
    height: 354px;
    width: 100%;
    background-image: url("../../assets/image/about/manage/manage_top_bg.png");
    background-size: 100%, 100%;
    background-repeat: no-repeat;
  }
  .manage_cont{
    width: 1100px;
    margin: 74px auto 92px auto;
    .manage_cont_title{
      width: 1098px;
      height: 27px;
      position: relative;
      border-color: #c8996c;
      border-style: solid;
      span {
        display: block;
        position: absolute;
        left: 0px;
        top: -20px;
        background: #ffffff;
        line-height: 32px;
        font-weight: 400;
        color: #caa574;
      }
    }
    .manage_desc{
      margin: 50px 0;
      display: flex;
      justify-content: center;
      justify-items: center;
      justify-self: center;
      .item{
        border-right: 1px solid #B18068;
        width: 25%;
        .item_icon{
          width: 82px;
          margin: 0 auto;
          text-align: center;
          .icon{
            width: 100%;
            height: 82px;
          }
          .icon_title{
            color: #3E3A39;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .item:last-child{
        border-right:none
      }
    }
    .partner{
      overflow: hidden;
      .partner_title{
        font-weight: 400;
        color: #525457;
        margin: 34px 0;
        font-size: 24px;
      }
      .partner_top{
        width: 100%;
        height: 270px;
        background-image: url("../../assets/image/about/manage/manage_img1.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .partner_desc{
        display: flex;
        justify-content: space-between;
        justify-items: center;
        justify-self: center;
        margin: 34px 0;
        .desc{
          .desc_name{
            font-size: 16px;
            font-weight: 400;
            color: #3F3A39;
          }
          .desc_num{
            margin-top: 20px;
            font-size: 28px;
            font-weight: 400;
            color: #B2836D;
          }
        }
      }
    }
    .manage_details{
      margin-top: 18px;
      display: flex;
      justify-content: space-between;
      justify-items: center;
      justify-self: center;
      .details_item{
        width: 33.3%;
        border-right: 1px solid #B18068;
        padding: 0 45px;
        .item_title{
          font-weight: 400;
          font-size: 24px;
          color: #525457;
          margin: 28px 0;
        }
        .item_img{
          width: 304px;
          height: 148px;
          img{
            width: 100%;
          }
        }
        .details_desc{
          margin-top: 28px;
          font-weight: 400;
          font-size: 16px;
          color: #3F3A39;
          line-height: 24px;
        }
      }
      .details_item:last-child{
        border-right:none
      }
      .details_item:first-child{
        padding-left: 0;
      }
    }
  }
}
</style>