<template>
<div>
  <NavBar></NavBar>
  <div class="brand">
    <div class="brand_top_img"></div>
    <div class="brand_cont">
      <div class="con_title">
        <span>品牌故事</span>
      </div>
      <div class="details_img"></div>
      <div class="text">
        也许，很多人对金恪不熟悉，但我们早已走进千家万户，在助力实现民族共同富裕的道路上奔走。我们的产业让更多的年轻人留在家乡，我们的酒店成为许多人旅途中的家，我们带着热爱生活的人们走遍世界，我们正在陪伴老人们安享晚年，帮助成千上万的亚健康人士恢复活力，更在通过努力以文化的力量打造有生命力的教育，这就是幸福生态践行者金恪的故事。
      </div>
      <div class="brand_cont_img"></div>
    </div>
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  name: "index",
  components: {
    Footer,
    NavBar,
  },
}
</script>

<style lang="scss" scoped>
.brand{
  .brand_top_img{
    height: 354px;
    width: 100%;
    background-image: url("../../assets/image/brand/top_img.png");
    background-size: 100%, 100%;
    background-repeat: no-repeat;
  }
  .brand_cont{
    width: 1100px;
    margin: 60px auto;
  .con_title {
    width: 1098px;
    height: 27px;
    border-bottom: 1px solid #c8996c;
    border-top: 1px solid #c8996c;
    border-right: 1px solid #c8996c;
    position: relative;
    span {
      display: block;
      position: absolute;
      left: 0px;
      top: -20px;
      background: #ffffff;
      font-size: 32px;
      line-height: 32px;
      font-weight: 400;
      color: #c8996c;
    }
  }
    .details_img{
      height: 290px;
      width: 100%;
      margin: 16px auto;
      background-image: url("../../assets/image/brand/img1.png");
      background-size: 100%, 100%;
      background-repeat: no-repeat;
    }
    .text {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      color: #3e3a39;
      margin: 20px 0 58px 0;
    }
.brand_cont_img{
  width: 952px;
  height: 3097px;
  margin: 32px auto;
  background-image: url("../../assets/image/brand/img2.png");
  background-size: 100%, 100%;
  background-repeat: no-repeat;
}
  }
}
</style>