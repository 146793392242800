<!--
 * @Author: jinzemin
 * @Date: 2021-04-28 09:10:55
 * @LastEditTime: 2021-05-06 17:33:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /jkoffical-ui/src/views/lg/home/news/index.vue
-->
<template>
  <div>
    <NavBar :activeIndex="'/lg/lgNews'" />
    <div class="title">
      <p class="text">
        新闻资讯
      </p>
      <el-divider></el-divider>
    </div>
    <div class="newCont">
      <div v-for="item in newsList" :key="item.id">
        <div class="newItem">
          <div class="newHeader">
            <a class="newThumb">
              <img :src="item.imageUrl" alt="" />
            </a>
            <div class="newContent">
              <div class="newTop">
                <p class="newTitle">{{ formatremark(item.title, 20) }}</p>
                <p class="newDate">{{ item.publishTime }}</p>
                <div class="newDesc">
                  {{ item.descDetail }}
                </div>
              </div>
              <div class="newBotm">
                <span class="more">
                  <router-link :to="`/lg/lgNewsDetails/${item.id}`">了解详情</router-link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
      </div>
      <div class="block">
        <div>共{{ total }}条新闻</div>
        <div class="right">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            @prev-click="handlePrev"
            @next-click="handleNext"
            :current-page.sync="currentPage"
            :page-size="5"
            layout="total, prev, pager, next"
            :page-count="pages"
            class="page"
          >
          </el-pagination>
          <div class="des">共{{ pages }}页</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/lgNavBar";
import Footer from "@/components/Footer";
import { Loading } from "element-ui";
export default {
  name: "vueName",
  data() {
    return {
      newsList: [],
      pages: 1,
      total: 0,
      currentPage: 1,
      loadingInstance: "",
    };
  },
  mounted() {
    this.getNewsList();
    this.loadingInstance = Loading.service({ fullscreen: true });
  },
  methods: {
    async getNewsList() {
      try {
        let res = await this.$axios.get(
          `/app/llg/news/page/2/${this.currentPage}`
        );
        if (res.status == 200 && res.data.code == 0) {
          this.newsList = res.data.data.list;
          this.total = res.data.data.totalCount;
          this.pages = res.data.data.totalPage;
          this.loadingInstance.close();
        } else {
          this.loadingInstance.close();
        }
      } catch (error) {
        this.loadingInstance.close();
        console.log(error);
      }
    },
    handleCurrentChange() {
      this.getNewsList();
    },
    handlePrev() {
      this.getNewsList();
    },
    handleNext() {
      this.getNewsList();
    },
    goNewsDetail(v) {
      this.$router.push(`/lg/lgNewsDetails/${v}`);
    },
    formatremark(val, num) {
      if (val != null) {
        if (val.length > num) {
          return val.substring(0, num) + "......";
        } else {
          return val;
        }
      }
    },
  },
  created() {},
  components: {
    Footer,
    NavBar,
  },
};
</script>
<style scoped lang="scss">
.title {
  margin-top: 40px;
  overflow: hidden;
  .text {
    position: relative;
    width: 1200px;
    font-size: 34px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    line-height: 34px;
    color: #414e56;
    text-align: center;
    height: 40px;
    margin: 0px auto;
  }
  .el-divider {
    background-color: #80bc9b;
    margin: 0;
  }
}
.newCont {
  width: 1200px;
  margin: 10px auto;
  .newItem {
    position: relative;
    padding: 30px 0;
    box-sizing: border-box;
    .newHeader {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      .newThumb {
        position: relative;
        -webkit-box-flex: 0;
        -webkit-flex: none;
        flex: none;
        width: 300px;
        height: 200px;
        background-color: aquamarine;
        margin-right: 30px;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .newContent {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        flex: 1;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        min-width: 0;
        min-height: 200px;
        .newTop {
          line-height: 32px;
          p {
            display: inline-block;
            color: #c8a374;
            font-family: MicrosoftYaHei;
            font-weight: 400;
          }
          .newTitle {
            font-size: 24px;
          }
          .newDate {
            font-size: 12px;
            float: right;
          }
          .newDesc {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: #595757;
            line-height: 28px;
            margin-top: 20px;
          }
        }
        .newBotm {
          .more {
            position: absolute;
            right: 0;
            bottom: 4px;
            background-color: #80bc9b;
            border-radius: 20px;
            text-align: center;
            line-height: 14px;
            padding: 10px 20px;
            font-size: 14px;
            color: #fff;
            a{
              text-decoration: none;
               color: #fff;
            }
          }
        }
      }
    }
  }
  .divider {
    border-bottom: 1px dashed #b5866c;
  }
  .block {
    display: flex;
    direction: row;
    justify-content: space-between;
    margin: 28px 359px 57px 360px;
    line-height: 30px;
    font-size: 16px;
    color: #3e3a39;

    .right {
      display: flex;
      direction: row;
      .des {
        margin-left: 10px;
      }
    }
    .right
      ::v-deep
      .el-pagination.is-background
      .el-pager
      li:not(.disabled).active {
      background-color: #bf906c !important;
    }
    .right::v-deep
      .el-pagination.is-background
      .el-pager
      li:not(.disabled):hover {
      color: #bf906c !important;
    }
  }
}
</style>
