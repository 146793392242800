<!--
 * @Author: your name
 * @Date: 2021-04-21 14:52:36
 * @LastEditTime: 2021-06-01 15:56:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /jkoffical-ui/src/views/qualification.vue
-->
<template>
  <!-- 资质信用 -->
  <div>
    <NavBar></NavBar>
    <div class="qualification">
      <img
        src="../assets/image/qualification/qualification-1-1.png"
        class="img1"
      />
      <div class="con">
         <div class="con_title">
          信用背书
        </div>
        <div class="con_temp">
          <div class="list">
            <img
              src="../assets/image/qualification/qualification_desc_6.png"
              alt=""
            />
          </div>
          <div class="list">
            <p class="desc">
              金恪集团是新华社旗下国家级信用平台——新华信用入驻企业，多年诚信发展获得高度认可。<br><br>
金恪集团联合新华信用发布“幸福创业+”计划，以金恪多年的产业经验及资源优势助力地方政府、中小微企业、个人消费者全方位发展。<br><br>
2021年11月，新华社对我国第六产业的发展状况和成绩进行了全面调研，并发布了名为《我国第六产业发展已成“燎原”之势 四大模式高端拓展夯实乡村振兴产业根基》的调研报告，对第六产业典型案例进行了深入剖析。其中，金恪集团作为中国“第六产业”代表性企业案例，被报告引述。
            </p>
           
          </div>
        </div>
        <div class="con_title">
          荣膺肯定
        </div>
        <div class="con_temp">
          <div class="list" style="  border-right: 1px solid #ba8b6c;">
            <p class="title">
              上海民营企业总部
            </p>
            <p class="desc">
              经过多年发展，金恪集团实现了规模、效益、文化、品牌的全面跃升，于2019年被认定为上海“民营企业总部”。
            </p>
            <img
              src="../assets/image/qualification/qualification_desc_1.png"
              alt=""
            />
          </div>
          <div class="list">
            <p class="title">
              浦东开发开放30周年优秀企业奖
            </p>
            <p class="desc">
              金恪集团紧抓上海浦东改革开放的优势及契机，始终聚焦幸福生活产业及服务，为人们打造幸福生活全产业生态链，因此在2020年荣获“浦东开发开放30周年优秀企业奖”。
            </p>
            <img
              src="../assets/image/qualification/qualification_desc_2.png"
              alt=""
            />
          </div>
        </div>
         <div class="con_temp">
          <div class="list" style="  border-right: 1px solid #ba8b6c;">
            <p class="title">
              第七届浦东总部经济十大经典样本
            </p>
            <p class="desc">
              金恪集团凭借打造区域产业融合创新样板，入选“浦东总部经济十大经典样本”。2021年荣获由上海浦东新区商务委员会颁发的“十大经典样本消费创新”奖杯。
            </p>
            <img
              src="../assets/image/qualification/qualification_desc_3.png"
              alt=""
            />
          </div>
          <div class="list">
            <p class="title">
              上海市贸易型总部
            </p>
            <p class="desc">
             金恪集团以独具创新的“金恪方案”，通过大农业、大文旅、大医养三大核心产业协同运营，以及强大的营销平台推动两大核心竞争力，实现效益倍增，为区域经济发展做出突出贡献，从而被上海市商务委员认定为2021年度“贸易型总部”。
            </p>
            <img
            class="catimg"
              src="../assets/image/qualification/qualification_desc_4.png"
              alt=""
            />
          </div>
        </div>
        <div class="con_title">
          资质信用
        </div>
        <div class="con_temp">
          <div class="item">
            <img
              src="../assets/image/qualification/qualification_desc_5.png"
              class="img2"
            />
          </div>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  components: {
    Footer,
    NavBar,
  },
};
</script>
<style lang="scss" scoped>
.qualification {
  .img1 {
    height: 352px;
    width: 100%;
  }
  .con {
    padding: 60px 0 43px 415px;
    width: 1099px;
    .con_title {
      height: 60px;
      line-height: 60px;
      text-align: center;
      font-size: 34px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #ba8b6c;
      border-bottom: 2px solid #ba8b6c;
    }
    .con_temp {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 32px 0;
      .list {
        margin-top: 64px;
        padding: 0 28px;
        height: 504px;
        width: 50%;
        .title {
          font-size: 24px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #ba8b6c;
        }
        .desc {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: #3e3a39;
          line-height: 28px;
          padding: 20px 0;
        }
        .catimg{
          width: 241px;
          height: 329px;
        }
        img {
          width: 100%;
        }
      }
      .item {
        padding: 0 15px;
        img {
          width: 100%;
        }
      }
    }
  }

  .img2 {
    width: 499px;
    display: block;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 95px;
  }
  .img3 {
    width: 477px;
    display: block;
    margin: 0 auto;
    margin-bottom: 95px;
  }
  .img4 {
    width: 739px;
    display: block;
    margin: 0 auto;
    margin-bottom: 95px;
  }
}
</style>
