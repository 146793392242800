<template>
  <div>
    <NavBar></NavBar>
    <div class="index">
      <div class="block">
        <el-carousel class="carouse" trigger="click" :height="height">
          <el-carousel-item
            v-for="item in carouseList"
            :key="item.id"
            class="citem"
          >
          <img v-if="item.clickType==0" :src='item.imageUrl' alt="" class="elimg" />
           <router-link v-else :to="item.gotoUrl" ><img :src="item.imageUrl" alt="" class="elimg"/></router-link>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 新闻动态 -->
      <div class="news" v-if="news.length > 0">
        <el-row
          type="flex"
          justify="space-between"
          align="bottom"
          class="new_top pad360"
        >
          <el-col class="new_title">新闻动态</el-col>
          <el-col
            class="new_more"
            @click.native="goPage(4)"
            style="font-size:14px;line-height:26px"
            >了解更多...</el-col
          >
        </el-row>
        <el-row class="line"></el-row>
        <div class="new_detail">
          <div
            class="item"
            v-for="(item, i) in news"
            :key="i"
            @click="goNewDetail(item)"
          >
            <div class="item_con">
              <div
                class="newt"
                style="font-size:18px;line-height:28px;height:52px"
              >
                {{ item.title }}
              </div>
              <div class="con" style="font-size:14px;line-height:26px">
                {{ item.descDetail }}
              </div>
              <div class="time">{{ item.publishTime }}</div>
            </div>
            <div class="new_line" v-if="i == 0 || i == 1"></div>
          </div>
        </div>
      </div>
      <div class="home_content">
        <div class="content">
          <div class="item">
            <div class="cont_item">
              <div class="cont_item_details top">
                <p class="title">幸福产业</p>
                <p class="dec">
                  金恪集团深度布局民生产业，精耕细作幸福生态，从农业食品到文化教育……
                </p>
                <p class="more"><a href="/core/happiness">More</a></p>
              </div>
              <img src="../assets/image/index/home_index1.png" alt="">
            </div>
          </div>
          <div  class="item">
            <div class="cont_item">
              <div class="cont_item_details bottom">
                <p class="title">消费营销</p>
                <p class="dec">
                  金恪认为，销售结果的达成才是真正营销的开始；零售商业不是库存的空间转移……
                </p>
                <p class="more"><a href="/core/consume">More</a></p>
              </div>
              <img src="../assets/image/index/home_index2.png" alt="">
            </div>
          </div>
          <div  class="item">
            <div class="cont_item">
              <div class="cont_item_details top">
                <p class="title">绿色发展</p>
                <p class="dec">
                  党的二十大报告明确提出，站在人与自然和谐共生的高度来谋划发展，这是高质量……
                </p>
                <p class="more"><a href="/core/greenDevelopment">More</a></p>
              </div>
              <img src="../assets/image/index/home_index3.png" alt="">
            </div>
          </div>
          <div  class="item">
            <div class="cont_item">
              <div class="cont_item_details bottom">
                <p class="title">科技创新</p>
                <p class="dec">
                  党的二十大报告中强调，加快实施创新驱动发展战略，完善科技创新体系……
                </p>
                <p class="more"><a href="/core/science">More</a></p>
              </div>
              <img src="../assets/image/index/home_index4.png" alt="">
            </div>
          </div>
          <div  class="item">
            <div class="cont_item">
              <div class="cont_item_details top">
                <p class="title">资本赋能</p>
                <p class="dec">
                  在产业孵化过程中，金恪注重发挥资本孵化和撬动作用，积极在产业创新发展领域……
                </p>
                <p class="more"><a href="/core/capital">More</a></p>
              </div>
              <img src="../assets/image/index/home_index5.png" alt="">
            </div>
          </div>
        </div>
      </div>
        <div class="declaration">
            <div class="top">严正声明</div>
            <div class="desc">
                <router-link to="declaration">
                    近期，接到举报有人冒用我公司（金恪控股集团股份有限公司）分子公司名义进行招聘事宜，并在招聘前期以培训、试岗等各种名义要求求职人员下载安装软件，以此获取个人财务信息达到非法牟利的目的。以上不法行为与“金恪控股集团股份有限公司”毫无关系，我司在此提醒并郑告广大求职人员通过正规正常途径求职面试，前期不提供任何个人保密信息，以免上当受骗，并积极向公安机关检举揭发共同打击网络犯罪行为，
                </router-link>
            </div>
        </div>

    </div>
    <Footer></Footer>

  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  data() {
    return {
      carouseList: [
        { path: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"},
        { path: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"},
      ],
      news: [], //新闻列表
      floatTitle: ["金恪大农业", "金恪大文旅", "金恪大医养"],
      active: 0,
      element: {
        nav_item: "",
        right_item: "",
      },
      type: 1,
      height:'0',
      warpStatus:false
    };
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.rightScrollEventListener);
  },
  mounted() {
    this.getCarouseList();
    this.getNews();
    this.init();
     window.onresize = () => {
      return (() => {
        this.height =
          (document.documentElement.clientWidth * 650) / 1620 + "px";
      })();
    };
  },
  components: {
    Footer,
    NavBar,
  },
  methods: {
     handleSatus(){
      this.warpStatus=!this.warpStatus
    },
    // 轮播图列表
    async getCarouseList() {
      try {
        let res = await this.$axios.get(`/app/index/lunbo/${this.type}`);
        if (res.status == 200 && res.data.code == 0) {
          this.carouseList = res.data.data;
        } else {
          console.log(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //获取新闻动态
    async getNews() {
      try {
        let res = await this.$axios.get("/app/index/news");
        if (res.status == 200 && res.data.code == 0) {
          this.news = res.data.data;
        } else {
          console.log(res.data.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 进入新闻详情页
    goNewDetail(v) {
      this.$router.push(`/news/detail?id=${v.id}`);
    },
    goPage(v) {
      if (v == 1) {
        this.$router.push("/core/agriculture");
      } else if (v == 2) {
        this.$router.push("/core/tourism");
      } else if (v == 3) {
        this.$router.push("/core/care");
      } else if (v == 4) {
        this.$router.push("/news/index");
      }else if (v == 5) {
        this.$router.push("/news/detail?id=505");
      }
    },
    // 初始化
    init() {
      this.element = {
        nav_item: document.getElementsByClassName("item_li"),
        right_item: document.getElementsByClassName("rightItem"),
      };
      this.element.nav_item[0].classList.add("item_li_bgcolor");
      window.addEventListener("scroll", this.rightScrollEventListener);
    },
    // 页面滚动监听,定位导航
    rightScrollEventListener() {
      const { nav_item, right_item } = this.element;
      let window_scrollTop =
        document.scrollTop || document.documentElement.scrollTop;
      const con = document.getElementsByClassName("index-img")[0].offsetTop;

      for (let i = 0; i < right_item.length; i++) {
        if (window_scrollTop >= con) {
          document
            .getElementsByClassName("float_window")[0]
            .classList.add("leftFixed");
        } else {
          document
            .getElementsByClassName("float_window")[0]
            .classList.remove("leftFixed");
        }
        let item_offsetTop = right_item[i].offsetTop + con - 400;

        if (window_scrollTop >= item_offsetTop) {
          for (let n = 0; n < nav_item.length; n++) {
            const current_classList = nav_item[n].classList;
            if (i === n) {
              current_classList.add("item_li_bgcolor");
            } else {
              current_classList.remove("item_li_bgcolor");
            }
          }
        }
      }
    },
    // 楼层导航驱动方法
    selectItem(i) {
      const con =
        document.getElementsByClassName("index-img")[0].offsetTop - 30;
      const { right_item } = this.element;
      let rightItem_offsetTop = right_item[i].offsetTop + con;
      let window_scrollTop =
        document.scrollTop || document.documentElement.scrollTop;
      let timer = {
        step: 50,
        times: 10,
        rightItem_offsetTop,
      };
      if (window_scrollTop < rightItem_offsetTop) {
        this.setScrollArrowUp(timer);
      } else if (window_scrollTop == rightItem_offsetTop) {
        return false;
      } else {
        this.setScrollArrowDown(timer);
      }
    },
    // 设置页面滚动
    setScrollArrowUp(v) {
      clearInterval(this.timer);
      const con =
        document.getElementsByClassName("index-img")[0].offsetTop - 80;
      this.timer = setInterval(() => {
        let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop <= v.rightItem_offsetTop + con) {
          document.documentElement.scrollTop = v.rightItem_offsetTop + con;
          document.body.scrollTop = v.rightItem_offsetTop + con;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop - v.step;
          document.body.scrollTop = window_scrollTop - v.step;
        }
      }, v.times);
    },
    // // 设置页面向下滚动
    setScrollArrowDown(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop >= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop + v.step;
          document.body.scrollTop = window_scrollTop + v.step;
        }
      }, v.times);
    },
  },
};
</script>

<style lang="scss" scoped>
.index {
  min-width: 1200px;
  .modal{
    z-index: 10000;
    width: 400px;
    position: fixed;
    right: 0;
    bottom: 20px;
    background-image: linear-gradient(to right,#b28a5a , #cea169);
    .title{
      font-size: 32px;
      font-family: PingFang;
      font-weight: 800;
      color: #FFFFFF;
      text-align: center;
      margin: 20px auto;
    }
    .warp{
      width: 360px;
      margin: 0 auto 20px auto;
      border-radius: 6px;
      background-color: #ffffff;
      font-size: 14px;
      font-family: PingFang,serif;
      font-weight: 300;
      color: #000000;
      line-height: 24px;
      padding: 16px;
      height: 300px;
      overflow-y: auto;
      .indent{
        text-indent: 2em;
        display: inline-block;
      }
      .footer-text{
        text-align: right;

      }
    }
  }
  .block {
    .carouse {
      width: 100%;
      height: 551px !important;
      .citem {
        height: 551px !important;
      }
      .elimg {
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .pad360 {
    padding: 0 360px;
  }
  .news {
    background: rgb(247, 248, 248);
    .new_top {
      height: 78px;
      padding-bottom: 20px;
      box-sizing: border-box;
      .new_title {
        font-size: 35px;
        line-height: 35px;
        font-weight: 400;
        color: #74808c;
      }
      .new_more {
        font-weight: bold;
        text-decoration: underline;
        color: #c8996c;
        text-align: right;
        cursor: pointer;
      }
    }
    .line {
      border-bottom: 2px solid #e7e7e7;
      width: 100%;
    }
    .new_detail {
      width: 100%;
      padding: 32px 360px 59px 360px;
      box-sizing: border-box;
      display: flex;
      direction: row;
      // justify-content: space-between;
      .item {
        width: 375px;
      }
      .newt {
        font-weight: 400;
        color: #caa574;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-bottom: 10px;
      }
      .con {
        padding: 25px 0 51px 0;
        font-weight: 400;
        color: #3e3a39;
        line-height: 26px;
        border-top: 1px solid #c8996c;
        border-bottom: 1px solid #c8996c;
        margin-top: 16px;
        height: 143px;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
      }
      .time {
        font-size: 16px;
        font-weight: bold;
        color: #c8996c;
        line-height: 25px;
        padding-top: 7px;
        padding-right: 18px;
      }
      .item {
        // display: flex;
        // direction: row;
        cursor: pointer;
        position: relative;
        margin-right: 38px;
        .item_con {
          width: 375px;
        }
      }
      .new_line {
        width: 1px;
        height: 100%;
        position: absolute;
        right: -19px;
        top: 0;
        background: rgb(237, 238, 238);
      }
    }
  }
  .home_content{
    width: 100%;
    max-height: 759px;
    overflow: hidden;
    .content{
      display: flex;
      justify-content: space-between;
      justify-items: center;
      .cont_item{
        position: relative;
        .cont_item_details{
          position: absolute;
          width: 100%;
          height: 380px;
          background-image: url("../assets/image/index/home_index_bg.png");
          .title{
            font-size: 40px;
            text-align: center;
            color: #FFFFFF;
            width: 218px;
            border-bottom: 2px solid #FFFFFF;
            padding: 22px 0;
            margin: 85px auto 0 auto;
          }
          .dec{
            margin: 28px auto 0 auto;
            width: 218px;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 24px;
          }
          .more{
            position: absolute;
            cursor: pointer;
            font-size: 16px;
            color: #FFFFFF;
            right: 82px;
            bottom: 86px;
            a{
              color: #FFFFFF;
            }
          }
        }
        .top{
          top: 0;
        }
        .bottom{
          bottom: 0;
        }
        img{
          width: 100%;
        }
      }

    }
  }
.declaration{
  position: fixed;
  bottom: 0;
  width: 500px;
  background-color: #ffffff;
  right: 40px;
  z-index: 1000;
  a{
    text-decoration: none;
    color: #3F3A39;
  }
  .top{
    background-color: #ba0017;
    height: 56px;
    color: #ffffff;
    text-align: center;
    line-height: 56px;
    width: 100%;
    font-size: 28px;
  }
  .desc{
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    padding: 20px;
    cursor: pointer;
  }
}
}
</style>
