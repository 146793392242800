<template>
  <div>
    <NavBar></NavBar>
    <div class="consume">
      <div class="consume_top_img"></div>
      <el-row class="consume_cont">
        <el-col class="left">
          <div
              v-for="(item, i) in leftList"
              :key="i"
              class="nav_item"
              @click.stop="selectItem(i)"
          >
            <p>{{ item.name }}</p>
            <img
                v-if="i === index"
                alt=""
                class="icon"
                src="../../assets/image/core/core-1-8.png"
            />
          </div>
        </el-col>
        <el-col class="right">
          <div>
            <div class="rightItem">
              <div class="con_title">
                <span>消费营销</span>
              </div>
              <div class="desc">
                <div class="desc_item">
                  金恪认为，销售结果的达成才是真正营销的开始；零售商业不是库存的空间转移，变现与共赢才是服务客户的王道。<br><br>
                  上海金恪新旺营销服务（集团）有限公司是一家领先的多元化生态投资和运营企业。新旺聚焦幸福生态领域，紧紧围绕“医、食、住、行、养、娱”的民生需求构建以“消费营销”为核心的业务板块。<br><br>
                  通过自主研发和投资并购实现对幸福生态相关产业及企业的升级优化，形成以“城市会客厅”为主要消费场景的品牌零售终端形象。业务涵盖食品酒饮、咖啡烘焙、医药健康、消费电子、个护清洁、母婴宠物、钟表黄金、生活旅游、大宗贸易、供应链服务、跨境业务等领域，围绕以家庭为核心的会员体系，打造线上和线下一站式整合服务平台，为市场提供更多优质的产品及服务。<br>
                </div>
                <div class="desc_item">
                  <div class="desc_item_img">
                    <img alt="" src="../../assets/image/core/consume/img.png">
                  </div>
                </div>
              </div>
            </div>
            <div class="rightItem">
              <p class="title">城市会客厅生态：金恪生活馆</p>
              <div class="consume_desc_img">
                <img alt="" src="../../assets/image/core/consume/img1.png">
              </div>
              <p class="consume_desc">
                金恪生活馆以多样化家庭客户为目标，扎根城市社区，将线下复合型会员消费服务体验与线上消费智能数字化服务相结合，充满发挥金恪产业闭环优势，打造出以云度酒庄、食恪乐购、金恪演艺中心、今客茶庄、智能电器体验馆、幸福通养老服务中心、福歆医药、金恪创新创业就业中心、金恪宠物、金恪文化艺术收藏馆、金恪大饭店、金老爷私房菜、金老爷咖啡、金老爷烘焙、公益驿站等为一体的城市社区幸福生态样板，努力构建新时代引领国民品质健康生活的“城市会客厅”。
              </p>
              <div class="consume_desc_img bor">
                <img alt="" src="../../assets/image/core/consume/img3.png">
              </div>
            </div>
            <div class="rightItem">
              <p class="title">智安康全域生态</p>
              <p class="consume_desc">
                智安康全域生态（ZAK Universal Ecosystem）是金恪集团基于二十余年产业运营管理经验打造的全球化新型全域生态平台。专注于与后疫情时代百姓品质生活息息相关的民生领域，自主研发ZAK OS系统，通过移动终端+车载终端+场景终端一体化，实现不同场景的无缝切换，提供品质健康生活服务优化与升级的智能系统解决方案。
              </p>
              <div class="consume_desc_img">
                <img alt="" src="../../assets/image/core/consume/img2.png">
              </div>
            </div>
            <div class="rightItem">
              <p class="title">零售品牌生态</p>
              <div class="consume_desc_img">
                <img alt="" src="../../assets/image/core/consume/img4.png">
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";

export default {
  name: "consume",
  data() {
    return {
      leftList: [
        {name: "消费营销", id: 0},
        {name: "金恪生活馆", id: 1},
        {name: "智安康全域生态", id: 2},
        {name: '零售品牌生态',id:3}
      ],
      index: 0,
      element: {
        nav_item: null,
        right_item: null,
      },
      timer: null,
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.rightScrollEventListener);
  },
  methods: {
    // 初始化
    init() {
      this.element = {
        nav_item: document.getElementsByClassName("nav_item"),
        right_item: document.getElementsByClassName("rightItem"),
      };
      this.element.nav_item[0].classList.add("colorActive");
      window.addEventListener("scroll", this.rightScrollEventListener);
    },
    // 页面滚动监听,定位导航
    rightScrollEventListener() {
      const {nav_item, right_item} = this.element;
      let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
      // const con = document.getElementsByClassName('con')[0].offsetTop

      for (let i = 0; i < right_item.length; i++) {
        if (window_scrollTop >= right_item[0].offsetTop + 100) {
          document.getElementsByClassName("left")[0].classList.add("leftFixed");
        } else {
          document
              .getElementsByClassName("left")[0]
              .classList.remove("leftFixed");
        }
        let item_offsetTop = right_item[i].offsetTop + 20 * i;

        if (window_scrollTop >= item_offsetTop) {
          for (let n = 0; n < nav_item.length; n++) {
            const current_classList = nav_item[n].classList;
            if (i === n) {
              current_classList.add("colorActive");
              this.index = n;
            } else {
              current_classList.remove("colorActive");
            }
          }
        }
      }
    },
    // 楼层导航驱动方法
    selectItem(i) {
      const {right_item} = this.element;
      let rightItem_offsetTop = right_item[i].offsetTop + 50;
      let window_scrollTop = document.scrollTop || document.documentElement.scrollTop;
      let timer = {
        step: 50,
        times: 10,
        rightItem_offsetTop,
      };
      if (window_scrollTop < rightItem_offsetTop) {
        this.setScrollArrowUp(timer);
      } else if (window_scrollTop == rightItem_offsetTop) {
        return false;
      } else {
        this.setScrollArrowDown(timer);
      }
    },
    // 设置页面滚动
    setScrollArrowUp(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
            document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop <= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop - v.step;
          document.body.scrollTop = window_scrollTop - v.step;
        }
      }, v.times);
    },
    // // 设置页面向下滚动
    setScrollArrowDown(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
            document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop >= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop + v.step;
          document.body.scrollTop = window_scrollTop + v.step;
        }
      }, v.times);
    },
  },
  components: {
    Footer,
    NavBar,
  },
}

</script>

<style lang="scss" scoped>
.consume {
  box-sizing: border-box;

  .consume_top_img {
    height: 354px;
    width: 100%;
    background-image: url("../../assets/image/core/consume/top_img.png");
    background-size: 100%, 100%;
    background-repeat: no-repeat;
  }

  .consume_cont {
    .left {
      width: 198px;
    }

    .right {
      padding: 89px 360px 0 666px;

      .con_title {
        width: 100%;
        height: 27px;
        border: 1px solid #c8a062;
        position: relative;
        margin-bottom: 20px;

        span {
          display: block;
          position: absolute;
          left: 0px;
          top: -20px;
          background: #ffffff;
          font-size: 32px;
          line-height: 32px;
          font-weight: 400;
          color: #c8a062;
          width: 200px;
          padding-left: 30px;
          box-sizing: border-box;
        }
      }

      .desc {
        display: flex;
        justify-content: center;
        justify-self: center;
        justify-items: center;

        .desc_item {
          color: rgba(63, 58, 57, 1);
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;

          .desc_item_img {
            width: 418px;
            margin-left: 28px;

            img {
              width: 100%;
            }
          }
        }
      }

      .rightItem {
        .title {
          padding: 40px 0;
          font-weight: 400;
          font-size: 24px;
          color: #525457;
        }

        .consume_desc {
          margin: 24px 0;
          font-weight: 400;
          font-size: 16px;
          color: #3F3A39;
          line-height: 28px;
        }

        .consume_desc_img {
          img {
            width: 100%;
          }
        }
        .bor{
          border: 1px solid #e2e2e2;
          border-radius: 4px;
        }
      }
    }

    .nav_item {
      color: #3e3a39;
      width: 198px;
      position: relative;

      :hover {
        cursor: pointer;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 47px;
        height: 46px;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        text-align: center;
      }

      .icon {
        display: block;
        width: 198px;
        height: 47px;
        position: absolute;
        left: 0;
        top: 0;
      }

      .fcolor {
        color: #c8a062;
      }
    }

  }

  .left {
    position: absolute;
    top: 80px;
    left: 360px;
  }

  .leftFixed {
    position: fixed !important;
    left: 360px !important;
    top: 140px !important;
  }

  .colorActive {
    color: #c8a062 !important;
  }

  .rt {
    margin-right: 75px;
  }
}
</style>
