<template>
  <!-- 赋能产业群 -->
  <div>
    <NavBar></NavBar>
    <div class="empower">
      <div class="carousel">
        <el-carousel trigger="click" arrow="never" height="380">
          <el-carousel-item
            v-for="item in carouseList"
            :key="item.id"
            class="carouse"
          >
            <el-image class="elimg" :src="item.path"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="con">
        <div class="con_title">
          <span>赋能产业群</span>
        </div>
        <div class="text" style="font-size:14px;line-height:26px">
          {{ text }}
        </div>
        <div class="core_img">
          <img
            src="../../assets/image/empower/empower-1-4.png"
            @click="goDetail(1)"
            alt=""
          />
          <img
            src="../../assets/image/empower/empower-1-5.png"
            @click="goDetail(2)"
            alt=""
          />
          <img
            src="../../assets/image/empower/empower-1-6.png"
            @click="goDetail(3)"
            alt=""
          />
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  data() {
    return {
      text: `金恪集团通过产业投资、营销体系、金融服务、资本运营等业务为实体产业提供开拓与支持，同时获得实体业务的运营支持与数据沉淀，以辅助实体业务实现数据化转型和智能化升级，推动消费数据化、产业金融化，以实现幸福产业生态体系的闭环构建和价值落地。`,
      carouseList: [
        {
          path: require("@/assets/image/empower/empower-1-1.png"),
        },
        {
          path: require("@/assets/image/empower/empower-1-2.png"),
        },
        {
          path: require("@/assets/image/empower/empower-1-3.png"),
        },
      ],
    };
  },
  components: {
    Footer,
    NavBar,
  },
  methods: {
    goDetail(v) {
      if (v == 1) {
        this.$router.push("/empower/marketing");
      } else if (v == 2) {
        this.$router.push("/empower/financial");
      } else {
        this.$router.push("/empower/investment");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.empower {
  .carousel {
    width: 100%;
    height: 380px;
    display: block;
    .elimg {
      width: 100%;
      height: 380px;
    }
  }
  .con {
    padding: 60px 0 62px 415px;
    width: 1196px;
    .con_title {
      width: 100%;
      height: 27px;
      border-bottom: 1px solid #e0e0e0;
      border-top: 1px solid #e0e0e0;
      border-right: 1px solid #e0e0e0;
      position: relative;
      span {
        display: block;
        position: absolute;
        left: 0px;
        top: -20px;
        background: #ffffff;
        font-size: 32px;
        line-height: 32px;
        font-weight: 400;
        color: #3e3a39;
      }
    }
    .text {
      // font-size: 14px;
      text-align: justify;
      font-weight: 400;
      color: #3e3a39;
      line-height: 26px;
      margin-top: 44px;
      margin-bottom: 53px;
    }
    .core_img {
      display: flex;
      direction: row;
      justify-content: space-between;
      img {
        width: 392;
        height: 324px;
        display: block;
        cursor: pointer;
      }
    }
  }
}
</style>
