<template>
<!-- 金融服务 -->
<div>
  <NavBar></NavBar>
   <div class="technology">
    <img class="img1" src="../../assets/image/empower/impower-4-1.png" alt="">
    <el-row class="con" >
      <el-col class="left">
        <div class="nav_item" v-for="(item,i) in leftList" :key="i" @click.stop="selectItem(i)">
          <p >{{item.name}}</p>
          <img class="icon" v-if="i==index" src="../../assets/image/core/core-1-8.png" alt="" >
        </div>
      </el-col>
      <el-col class="right">
        <!-- <div class="rightItem">
          <div class="con_title">
            <span>金融服务</span>
          </div>
          <img class="img2" src="../../assets/image/empower/impower-4-2.png" alt="">
          <div class="text" style="font-size:14px;line-height:26px">{{text1}}</div>
          <div class="text" style="font-size:14px;line-height:26px">{{text2}}</div>
          <div class="text" style="font-size:14px;line-height:26px">{{text3}}</div>
          <img class="img3" src="../../assets/image/empower/impower-4-3.png" alt="">
        </div> -->
        <div class="type rightItem">
          <div class="title">精确数科</div>
          <img class="img5" src="../../assets/image/empower/impower-4-4.png" alt="">
          <div class="text" style="font-size:14px;line-height:26px">{{text4}}</div>
          <el-row class="ul text">
            <el-col :span="8">• 中国B2B供应链金融创新大奖 </el-col>
            <el-col :span="10">• 中国B2B供应链金融十大卓越科技公司荣誉称号</el-col>
            <el-col :span="6">• 2018中国B2B新锐势力企业</el-col>
          </el-row>
          <el-row class="ul text">
            <el-col :span="8">• 2019卓越竞争力金融科技影响力企业 </el-col>
            <el-col :span="8">• 2020金融科技影响力品牌”大奖</el-col>
            <el-col :span="8"></el-col>
          </el-row>
        </div>
        <div class="type rightItem">
          <div class="title">诚立保险经纪有限公司</div>
          <img class="img5" src="../../assets/image/empower/impower-4-5.png" alt="">
          <div class="text" style="font-size:14px;line-height:26px">{{text5}}</div>
          <div class="text" style="font-size:14px;line-height:26px">{{text6}}</div>
        </div>
        <div class="type rightItem">
          <div class="title">香港百联资本</div>
          <img class="img5" src="../../assets/image/empower/impower-4-6.png" alt="">
          <div class="text" style="font-size:14px;line-height:26px">{{text7}}</div>
        </div>
        <div class="type rightItem">
          <div class="title">金恪家族办公室</div>
          <img class="img5" src="../../assets/image/empower/impower-4-7.png" alt="">
          <div class="text" style="font-size:14px;line-height:26px">{{text8}}</div>
          <div class="text" style="font-size:14px;line-height:26px">{{text9}}</div>
        </div>
      </el-col>
    </el-row>
  </div>
    <Footer></Footer> 
</div>
 
</template>

<script>
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'
  export default {
    data() {
      return {
        text1:`金恪集团主张“金融、科技、产业”融合发展，金恪金融服务业务基于产业交易的场景化服务体系，使金恪集团“金融+产业+科技”形成完整生态闭环。`,
        text2:` 得益于“医、食、住、行、养、娱”产业和大数据技术的迅速发展，金恪拥有了规模庞大的客户群体与经销商数据，基于客户交易行为及金融服务需求，金恪金融产业应运而成。`,
        text3:` 通过深度挖掘用户潜在金融需求和风险级别，高效匹配用户痛点，精准获取用户需求，金恪金融业务提供包括产业供应链上下游金融服务、个人消费金融服务、产业交易保证保险、个人消费保险等与之匹配的定制化金融服务产品，真正成为用户的不可或缺的金融帮手，提高用户交易频度和满意度，推动场景的快速发展，助力幸福产业的可持续成长。`,
        text4:`精确数科集团成立于2014年8月，是金恪集团旗下践行普惠金融，打造一个以产融结合为基础、服务于金恪体系内外产业，涵盖普惠金融、精准营销、金融科技服务等业务版块的金融科技服务集团，为个人及中小微企业提供综合金融服务。`,
        text5:`上海诚立保险经纪有限公司是经中国保险监督管理委员会批准成立的全国性保险经纪公司。公司总部设在上海，面向全国经营寿险、非寿险、再保险经纪业务及风险管理咨询服务。 `,
        text6:` 诚立保险经纪有限公司基于客户利益，围绕客户的风险管理和保险需求，致力于为客户提供全面的风险解决方案。在2010年保监会关于保险专业中介机构的信用评级中荣获“五星级”行业评级。`,
        text7:`香港百联资本集团作为一家成熟的投资及顾问公司，依托集团坚实的财力资本与金融储备，成就客户的投资理想。凭借在多样化全球业务平台累积的深刻见解和丰富的实践经验，在专业、高效、稳健、合规的基础上，为客户提供信托服务、家族办公室、资产管理、私募股权、信贷按揭、特别项目投资以及企业融资顾问服务等解决方案。`,
        text8:`金恪家族办公室是经中国证券投资基金业协会登记备案的私募投资基金管理人，从事私募投资基金和资产管理业务。核心管理团队均系知名金融机构具备多年投资经验的专业人士，通过与国内各大券商、基金、银行、信托等金融机构密切的合作关系，努力实现合作伙伴、客户和公司的多方共赢，为客户提供高效优质的资产管理服务。 `,
        text9:` 金恪家族办公室凭借雄厚的综合实力和资产管理领域的深入探索，2019年获得“2019年度基金与财富管理·介甫奖”、“潜力对冲基金管理人”殊荣。同时，金恪家族办公室旗下金恪先锋指数1号产品，荣获“优秀指数增强型基金产品创新奖”。`,
        leftList:[
          // {name:"金融服务",id:0},
          {name:"精确数科",id:1},
          {name:"保险经纪",id:2},
          {name:"香港百联资本",id:3},
          {name:"金恪家族办公室",id:4},
        ],
        index:0,
        element:{
          nav_item:null,
          right_item: null
        },
        timer: null,
      }
    },
    mounted () {
      this.init()
    },
     components:{
    Footer,
    NavBar
  },
    beforeDestroy(){
      window.removeEventListener('scroll',this.rightScrollEventListener)
    },
    methods: {
      // 初始化
      init() {
        this.element = {
          nav_item:document.getElementsByClassName('nav_item'),
          right_item:document.getElementsByClassName('rightItem'),
        }
        this.element.nav_item[0].classList.add('colorActive')
        window.addEventListener('scroll',this.rightScrollEventListener)
      },
      // 页面滚动监听,定位导航
      rightScrollEventListener(){
        const {nav_item,right_item} = this.element
        let window_scrollTop = document.scrollTop || document.documentElement.scrollTop
        // eslint-disable-next-line no-unused-vars
        const con = document.getElementsByClassName('con')[0].offsetTop

        for(let i = 0; i < right_item.length; i++){
          if(window_scrollTop >= right_item[0].offsetTop + 100){
            document.getElementsByClassName('left')[0].classList.add("leftFixed")
          }else{
            document.getElementsByClassName('left')[0].classList.remove("leftFixed")
          }
          let item_offsetTop = right_item[i].offsetTop + 20*i
          
          if(window_scrollTop >= item_offsetTop){
            for(let n = 0; n < nav_item.length; n ++){
              const current_classList = nav_item[n].classList
              if(i === n){
                current_classList.add('colorActive') 
                this.index = n
              }else{
                current_classList.remove('colorActive')
              }
            }
          }
        }
      },
      // 楼层导航驱动方法
      selectItem(i){
        const {right_item} = this.element
        let rightItem_offsetTop = right_item[i].offsetTop + 50
        let window_scrollTop = document.scrollTop || document.documentElement.scrollTop
        let timer = {
          step: 50,
          times: 10,
          rightItem_offsetTop,
        }
        if(window_scrollTop < rightItem_offsetTop){
          this.setScrollArrowUp(timer)
        }else if(window_scrollTop == rightItem_offsetTop){
          return false
        }else{
          this.setScrollArrowDown(timer)
        }
      },
      // 设置页面滚动
      setScrollArrowUp(v){
        clearInterval(this.timer)
        this.timer = setInterval(() =>{
          let window_scrollTop = document.scrollTop || document.documentElement.scrollTop
          if(window_scrollTop <= v.rightItem_offsetTop){
            document.documentElement.scrollTop = v.rightItem_offsetTop
            document.body.scrollTop = v.rightItem_offsetTop
            clearInterval(this.timer)
          }else{
            document.documentElement.scrollTop = window_scrollTop - v.step
            document.body.scrollTop = window_scrollTop - v.step
          }
        },v.times)

      },
      // // 设置页面向下滚动
      setScrollArrowDown(v){
        clearInterval(this.timer)
        this.timer = setInterval(() =>{
          let window_scrollTop = document.scrollTop || document.documentElement.scrollTop
          if(window_scrollTop >= v.rightItem_offsetTop){
            document.documentElement.scrollTop = v.rightItem_offsetTop
            document.body.scrollTop = v.rightItem_offsetTop
            clearInterval(this.timer)
          }else{
            document.documentElement.scrollTop = window_scrollTop + v.step
            document.body.scrollTop = window_scrollTop + v.step
          }
        },v.times)
      },
    },
  }
</script>

<style lang="scss" scoped>
.technology{
  .img1{
    width: 100%;
    height: 300px;
    display: block;
  }
  .con{
    .left{
      width: 198px;
    }
    .nav_item{
      color: #3e3a39;
      width: 198px;
      position: relative;
      :hover{
        cursor: pointer;
      }
      p{
        font-size: 18px;
        font-weight: 400;
        line-height: 47px;
        height: 46px;
        border-bottom: 1px solid #E0E0E0;
        width: 100%;
        text-align: center;
      }
      .icon{
        display: block;
        width: 198px;
        height: 47px;
        position: absolute;
        left: 0;
        top: 0;
      }
      .fcolor{
        color: #C8A062;
      }
    }
    .right{
      padding: 89px 360px 63px 666px;
      .con_title{
        width: 100%;
        height: 27PX;
        border-bottom: 1px solid #e7e7e7;
        border-top: 1px solid #e7e7e7;
        border-right: 1px solid #e7e7e7;
        border-left: 1px solid #e7e7e7;
        position: relative;
        span{
          display: block;
          position: absolute;
          left: 0px;
          top: -20px;
          background: #ffffff;
          font-size: 32PX;
          line-height: 32PX;
          font-weight: 400;
          color: #3e3a39;
          width: 200PX;
          padding-left: 30px;
          box-sizing: border-box;
        }
      }
      .img2{
        width: 100%;
        height: 252px;
        margin-top: 10px;
      }
      .text{
        // font-size: 14px;
        font-weight: 400;
        color: #3e3a39;
        line-height: 26px;
        margin-bottom: 20px;
        text-align: justify;
      }
      .img3{
        height: 403px;
        // width: 100%;
        margin-top: 40px;
        width: 86%;
        margin-left: 7%;
      }
      .title{
        font-size: 24px;
        font-weight: 400;
        color: #3e3a39;
        line-height: 30px;
      }
      .text{
        // font-size: 14px;
        font-weight: 400;
        color: #3e3a39;
        line-height: 26px;
        margin: 20px 0 17px 0;
        text-align: justify;
      }
      .img5{
        width: 100%;
        height: 137px;
        margin-top: 19px;
      }
      .type{
        margin-top: 58px;
        .text{
          margin: 29px 0 0 0;
        }
      }
      
      .ul{
        padding: 0;
        margin: 0;
        display: flex;
        direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        p{
          width: 33%;
        }
      }
    }
  }
  .left{
    position: absolute;
    top: 80px;
    left: 360px;
  }
  .leftFixed{
    position: fixed !important;
    left: 360px !important;
    top: 140px !important;
  }
  .colorActive{
    color:#C8A062 !important;
  }
}
</style>