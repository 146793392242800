<!--
 * @Author: your name
 * @Date: 2021-04-29 10:45:51
 * @LastEditTime: 2021-05-12 17:21:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /jkoffical-ui/src/components/lgNavBar.vue
-->
<template>
  <div>
    <div class="bananer">
      <img src="../assets/image/lg/bananer.jpg" alt="" />
    </div>
    <div class="navbar">
      <el-row>
        <el-menu
          router
          :default-active="index"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#C8A374"
          active-text-color="#C8A374"
        >
          <el-menu-item index="/lg/home">首页</el-menu-item>
          <el-menu-item index="/lg/lgNews">新闻资讯</el-menu-item>
          <el-menu-item index="/lg/shoot">拍摄场地</el-menu-item>
          <el-menu-item index="/lg/lgVideo">精彩视频</el-menu-item>
          <el-menu-item index="/lg/product">产品展示</el-menu-item>
        </el-menu>
      </el-row>
    </div>
    <div class="homeCarousel">
      <div class="carousel">
        <el-carousel :height="height">
          <el-carousel-item v-for="(item,index) in lunboTop" :key="index">
            <img :src="item.imageUrl" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeIndex:{
      type:String,
      default:function(){
        return "/lg/home"
      }
    }
  },
  name: "vueName",
  data() {
    return {
      index:"/lg/home",
      height:'0',
      lunboTop:[]
    };
  },
  mounted() {
    this.getCarouseList()
    this.height = (document.documentElement.clientWidth * 650) / 1920 + "px";
  },
  methods: {
     async getCarouseList() {
      try {
        let res = await this.$axios.get("/app/llg/index/content");
        if (res.status == 200 && res.data.code == 0) {
          this.lunboTop = res.data.data.lunboTop;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.index=this.activeIndex
  },
  components: {},
};
</script>

<style scoped lang="scss">
.bananer {
  img {
    height: 140px;
    width: 100%;
  }
}
.navbar {
  width: 1200px;
  margin: 0 auto;
  height: 64px;
  .el-menu {
    display: flex;
    justify-content: space-between;
    .el-menu-item {
      height: 64px !important;
      line-height: 64px !important;
    }
    .el-menu-item.is-active {
      border-bottom: 4px solid #80bc9b !important;
    }
  }
}
.homeCarousel {
  margin-top: 10px;
  .carousel {
    img {
      width: 100%;
      height: 100%;
      object-fit:cover
    }
  }
}</style>
