<!--
 * @Author: your name
 * @Date: 2021-04-28 09:11:11
 * @LastEditTime: 2021-04-29 10:43:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /jkoffical-ui/src/views/lg/home/news/newDetails.vue
-->
<template>
 <div>
<NavBar :activeIndex="'/lg/lgNews'" />
 <div class="news_detail">
      <div class="con">
        <p class="title">{{ detail.title }}</p>
        <p class="time">
          编辑:{{ detail.source }} | 发布时间:{{ detail.publishTime }}
        </p>
        <div class="detail" v-html="detail.content"></div>
      </div>
    </div>
    <Footer />
 </div>
</template>
 
<script>
import NavBar from "@/components/lgNavBar";
import Footer from "@/components/Footer";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      detail: {},
      id: "",
      loadingInstance: "",
    };
  },
  mounted() {
    this.loadingInstance = Loading.service({ fullscreen: true });
    this.id = this.$route.params.id;
    this.getDetail();
  },
  methods: {
    async getDetail() {
      try {
        let res = await this.$axios.get(`/app/llg/news/detail/${this.id}`);
        if (res.status == 200 && res.data.code == 0) {
          this.loadingInstance.close();
          this.detail = res.data.data;
        } else {
          this.loadingInstance.close();
          console.log(res.data.msg);
        }
      } catch (error) {
        this.loadingInstance.close();
        console.log(error);
      }
    },
  },
  components: {
    Footer,
    NavBar,
  },
};
</script>
 
<style scoped lang = "scss">
 .news_detail {
  .con {
    padding: 49px 360px 100px 360px;
    .title {
      font-size: 24px;
      color: #485157;
      line-height: 26px;
      text-align: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #c6976c;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .time {
      font-size: 18px;
      color: #c6976c;
      line-height: 25px;
      margin: 20px 0;
      text-align: center;
    }
    ::v-deep .detail {
      font-size: 18px !important;
      font-weight: 400 !important;
      color: #3e3a39 !important;
      line-height: 35px !important;
    }
    ::v-deep img {
      margin-bottom: 10px;
    }
  }
}
</style>