<template>
<!-- 头部导航 -->
  <div class="navBar">
    <el-row type="flex" align="middle" class="nav" justify="center">
      <el-col class="logo">
        <img src="../assets/image/index/logo.png" alt="">
      </el-col>
      <el-col class="content" >
        <el-menu
          :default-active="curActive"
          :router="true"
          class="el-menu-demo"
          mode="horizontal"
          menu-trigger="hover"
          background-color="#4c4948"
          text-color="#fff"
          active-text-color="#C1926C">
          <el-menu-item index="/">首页</el-menu-item>
          <el-submenu :index="jkAbout.path">
            <template  slot="title">{{jkAbout.title}}</template>
            <el-menu-item id="menu_item1" :index="item.path" v-for="item in jkAbout.children" :key="item.id">
              {{item.name}}
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="/news/index" class="tong1">新闻动态</el-menu-item>
<!--          <el-submenu index="industrialDistribution">-->
<!--            <template slot="title">{{industry.title}}</template>-->
<!--             <el-menu-item id="menu_item1" :index="item.path" v-for="item in industry.children" :key="item.id">-->
<!--              {{item.name}}-->
<!--            </el-menu-item>-->
<!--          </el-submenu>-->
          <el-submenu class="tong1" index="/jkCulture/sixWays">
            <template slot="title">{{jkCulture.title}}</template>
            <el-menu-item :index="item.path" v-for="item in jkCulture.children" :key="item.id">{{item.name}}</el-menu-item>
          </el-submenu>
          <el-menu-item class="tong1" index="/brand">品牌故事</el-menu-item>
          <el-menu-item class="tong1" index="/duty">社会责任</el-menu-item>
          <el-menu-item index="/honor" class="tong1">企业荣誉</el-menu-item>
        </el-menu>
      </el-col>
  </el-row>
  </div>
</template>
<style lang="scss">
  .el-menu-demo{
    min-width: 1200px;
  }
  .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow{
    display: none;
  }
  #menu_item1{
    background: rgba($color: #000000, $alpha: 0.7);
  }
  .el-menu--popup-bottom-start{
    background: rgba($color: #000000, $alpha: 0.7);
  }
  .el-menu--horizontal>.el-menu-item,.el-menu--horizontal>.el-submenu .el-submenu__title{
    height: 97px !important;
    line-height: 97px !important;
    font-size: 18px !important;
    padding: 0 !important;
    text-align: center;
    width: 150px;
  }
  .el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title{
    font-size: 18px !important;
    height: 42px !important;
    line-height: 42px !important;
    border-bottom: 1px solid #666666;
    text-align: center !important;
  }
  .el-menu.el-menu--horizontal{
    border-bottom: none !important;
  }
  .el-menu--popup-bottom-start{
    margin-top: 0 !important;
    opacity: 0.8 !important;
  }
  .el-menu--popup{
    border-radius:0 !important;
    box-shadow:none !important;
    padding: 0 !important;
  }
  .el-menu--collapse .el-menu .el-submenu, .el-menu--popup{
    min-width: 150px !important;
  }
  .el-menu-item.is-disabled{
    opacity: 1 !important;
  }
  .el-menu-item-group__title{
    padding: 0;
  }
</style>

<script>
import navlist from '../assets/js/navtitle'
  export default {
    data() {
      return {
        navlist:navlist,
        activeIndex: '/',
        curActive: '/',
        jkAbout:[],//关于金恪
        industry:[],//产业布局
        jkCulture:[],//金恪文化
      };
    },
    mounted(){
      this.jkAbout = navlist[0]
      this.jkCulture = navlist[2]
      if(this.$route.path === '/news/detail'){
        this.curActive = '/news/index'
      }else{
        this.curActive = this.$route.path
      }
      // this.curActive = this.$route.path
    },
    watch: {
      $route (val) {
        let path = val.path
        if(path === '/news/detail'){
          this.curActive = '/news/index'
        }else{
          this.curActive = val.path
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.navBar{
  height: 98px;
  padding-left: 110PX;
  .nav{
    min-width: 1200PX;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    background: #4c4948;
    z-index: 1000;
    padding-left: 96px;
    .logo{
      width: 179px;
      height: 62px;
      padding: 17px 110px 17px 0px;
      box-sizing: content-box;
      img{
        display: inline-block;
        width: 179px;
        height: 62px;
      }
    }
    a{
      text-decoration: none;
      font-size: 18PX;
      display: block;
      line-height: 97PX;
    }
  }
}
</style>
