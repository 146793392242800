<!--
 * @Author: your name
 * @Date: 2021-04-21 14:52:36
 * @LastEditTime: 2021-04-27 09:27:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /jkoffical-ui/src/App.vue
-->
<template>
  <div id="app">

     <router-view/>
  </div>
</template>
<script>

export default {
  
}
</script>
<style lang="scss">
  *{
    margin: 0;
    padding: 0;
  }
  html,body{
    margin: 0;
    padding: 0;
    height: 100%;
    min-width: 1200px;
  }
  html{
    margin: 0;
    padding: 0;
    #app{
        min-width: 1200Px !important;
        box-sizing: border-box;
        font-size: 16px;
        line-height: 16px;
        font-family:'Microsoft YaHei' !important;
    }
  }
</style>
