<template>
<div>
    <NavBar></NavBar>
    <div class="declaration">
        <div class="title">严正声明</div>
        <p class="desc">近期，接到举报有人冒用我公司（金恪控股集团股份有限公司）分子公司名义进行招聘事宜，并在招聘前期以培训、试岗等各种名义要求求职人员下载安装软件，以此获取个人财务信息达到非法牟利的目的。以上不法行为与“金恪控股集团股份有限公司”毫无关系，我司在此提醒并郑告广大求职人员通过正规正常途径求职面试，前期不提供任何个人保密信息，以免上当受骗，并积极向公安机关检举揭发共同打击网络犯罪行为，同时，我们对那些冒用我公司名义从事各种违法犯罪活动的个人或单位予以强烈谴责，并发布如下声明：</p>
        <p class="desc">一、我公司全称为“金恪控股集团股份有限公司”，注册地点在上海，公司从未涉及任何正式入职前的培训、试岗，未涉及任何软件工具下载事宜。</p>
        <p class="desc">二、我公司有严格的招聘流程和管理制度，绝对禁止通过任何软件、试岗等形式在正式入职前要求提供应聘者的个人信息、财务信息并进行资金交易往来，诈骗分子要求参与者涉及以上行为，明显不是我公司所为，请广大公众予以充分鉴别。</p>
        <p class="desc">三、鼓励相关受骗人员及时向公安机关报案，早日追回被骗的钱款。</p>
        <p class="desc">四、凡冒用我公司名义实施诈骗行为的单位或个人，我公司将保留追究法律责任的权利，构成刑事犯罪的将依法交由公安机关处理。</p>
        <p class="desc">郑告大家：不要听信任何谣言和虚假陈述，防范犯罪分子利用互联网、手机APP、网络软件、微信等实施诈骗活动，避免自己的财产蒙受不必要的巨额损失!</p>
        <p class="desc">特此声明!</p>
        <p class="desc" style="text-align: right">金恪控股集团股份有限公司</p>
        <p class="desc" style="text-align: right">2023年5月22日</p>
    </div>
    <Footer></Footer>
</div>
</template>
<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export  default {
    data(){

    },
    components: {
        Footer,
        NavBar,
    },
}
</script>
<style scoped lang="scss">
.declaration{
  width: 1200px;
  margin: 40px  auto;
  .title{
    font-size: 44px;
    color: #c79359;
    text-align: center;
    line-height: 44px;
    margin: 10px 0;
  }
  .desc{
    font-size: 16px;
    line-height: 32px;
    padding: 10px 0;
  }
}
</style>
