<!--
 * @Author: jinzemin jinzm@jkinvest.cn
 * @Date: 2023-01-11 09:31:08
 * @LastEditors: jinzemin jinzm@jkinvest.cn
 * @LastEditTime: 2023-01-11 10:17:10
 * @FilePath: /jkoffical-ui/src/views/newAbout.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="top">
      <div>
        <img src="../assets/image/newAbout/topLogo.png" alt="" />
      </div>
    </div>
    <div class="topheader"></div>
    <div class="main">
      <div class="title">
        <span>关于金恪</span>
      </div>
      <div class="details">
        <p class="desc">
          金恪集团实缴注册资本人民币32亿元，是在多个行业拥有领先企业的大型产业投资运营集团，连年资信评级AA+。
        </p>
        <p class="desc">
          经过20多年的发展，金恪始终聚焦实体经济领域，紧紧围绕“医、食、住、行、养、娱”民生需求布局产业，构建“产业运营”与“消费营销”两大核心业务板块，通过技术创新、管理提升、金融服务、资源配置等能力，实现对高潜力产业及企业的投资及孵化，形成独特的品牌、渠道、营销核心竞争优势护城河，推动公司整体价值的持续增长，为市场提供更多优质的产品及服务，为股东提供长期优质回报，为地区发展储备产业集群，为中国经济高质量发展贡献力量。
        </p>
        <p class="desc">
          金恪集团全力打造以家庭为核心的客群体系，全方位深度服务顾客；构建优秀的治理结构与企业文化，为客户创造多元的事业发展平台；积极承担社会责任，在乡村振兴、公益养老、兴边富民等领域进行长期持续投入，努力为国家和社会做出更多更大贡献
        </p>
      </div>
      <div class="detailsImg">
        <img :src="require('../assets/image/newAbout/detailsImg-1.png')" />
      </div>
      <div class="title">
        <span>产业布局</span>
      </div>
      <div class="details">
        <p class="desc">
          金恪以“共创共富”为战略总目标和发展主航道，以幸福生活产业闭环和消费营销流量闭环为两大核心，凭借平台、流量、产品、服务及资本优势构建金恪产业生态闭环，成功打造金恪独特的产业增值模式，以助力地方经济高质量发展与实现共同富裕。
        </p>
        <p class="desc">
          金恪幸福生活产业布局“医食住行养娱”六大民生领域，精耕农业食品、酒店度假、康养运营、产业地产、文化艺术、矿产资源等实体产业，打造幸福生活全产业链。
        </p>
        <p class="desc">
          金恪消费营销以金恪生活馆作为线下+线上一站式整合服务平台，打通国潮酒庄、连锁药店、生鲜便利店、养老服务中心、旅行社、培训教育中心、智能家电商城等多元业态，为客群提供个性化智能定制服务以及沉浸式消费体验。
        </p>
      </div>
      <div class="title">
        <span>发展历程</span>
      </div>
      <div class="detailsImg">
        <img :src="require('../assets/image/newAbout/detailsImg-2.png')" />
      </div>
      <div class="details">
        <p class="desc">
          金恪集团的成长发展经历了中国经济高速增长的20年，经历了中国人民收入水平和生活水平飞速提高的20年。经过不断战略探索与产业实践，金恪始终相信人的需求是经济发展的价值本源。
        </p>
      </div>
      <div class="courseDetaisl">
        <div class="left">
          <p class="date">1999-2014</p>
          <p class="name">产业筑基</p>
        </div>
        <div class="right">
          <p class="desc">
            金恪从文旅业务起步，紧抓时代机遇，产业涉足农业食品、酒店度假、地产开发等领域，建立了遍及全国30多个省市的酒店度假网络、现代农业示范基地及地产项目，企业实力得到稳步提升。
          </p>
        </div>
      </div>
      <div class="courseDetaisl">
        <div class="left">
          <p class="date">2014-2022</p>
          <p class="name">平台势能</p>
        </div>
        <div class="right">
          <p class="desc">
            2014年，母公司金恪投资控股股份有限公司成立，以平台优势整合产业发展，延伸布局康养运营、文化艺术、矿产资源、绿色发展、消费营销、投资孵化等领域，合纵连横并购上下游优质企业，集团规模跻身中国民企前列。
          </p>
        </div>
      </div>
      <div class="courseDetaisl">
        <div class="left">
          <p class="date">2022-至今</p>
          <p class="name">共创共富</p>
        </div>
        <div class="right">
          <p class="desc">
            在中共二十大为中国未来发展指明方向的2022年，金恪进行再创业式战略调整与组织变革，聚焦产业运营能力与消费营销能力的打造，以品牌、资源、平台、流量、金融优势让金恪成为产业运营及资本市场孵化器，为企业发展赋能，为地方发展助力，为实现“共同富裕”贡献民企力量。
          </p>
        </div>
      </div>
      <div class="title">
        <span>企业文化</span>
      </div>
      <div class="detailsImg">
        <img :src="require('../assets/image/newAbout/detailsImg-3.png')" />
      </div>
      <div class="details">
        <p class="desc">
          金恪文化早已成为金恪肌体中亘古流淌的一种精神，成为金恪永续前行的核心竞争力，金恪人以金恪六道"行远自迩、以人为本、抱诚守真、古道热肠、匠心独运、敦本务实"修身，以社会责任为己任，以包容、开放、诚信的姿态，坚守内心的信仰，恪守金子般的承诺。
        </p>
      </div>
      <div class="courseDetaisl">
        <div class="left" style=" width: 100px;">
          <p class="name">使命</p>
        </div>
        <div class="right">
          <p class="desc">
            打造产业增值体系，共建人民幸福生活。
          </p>
        </div>
      </div>
      <div class="courseDetaisl">
        <div class="left" style=" width: 100px;">
          <p class="name">愿景</p>
        </div>
        <div class="right">
          <p class="desc">
            成为受人尊敬的价值增长与价值分享创新型企业。
          </p>
        </div>
      </div>
      <div class="courseDetaisl">
        <div class="left" style=" width: 100px;">
          <p class="name">定位</p>
        </div>
        <div class="right">
          <p class="desc">
            幸福生态践行者。
          </p>
        </div>
      </div>
      <div class="title">
        <span>荣誉</span>
      </div>
      <div class="honorList">
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="item">
              <img :src="require('../assets/image/newAbout/honor-1.png')" />
              <p class="name">上海市贸易型企业总部</p>
            </div></el-col
          >
          <el-col :span="6">
            <div class="item">
              <img :src="require('../assets/image/newAbout/honor-2.png')" />
              <p class="name">上海市民营企业总部</p>
            </div></el-col
          >
          <el-col :span="6">
            <div class="item">
              <img :src="require('../assets/image/newAbout/honor-3.png')" />
              <p class="name">新华信用平台入驻企业</p>
            </div></el-col
          >
          <el-col :span="6">
            <div class="item">
              <img :src="require('../assets/image/newAbout/honor-4.png')" />
              <p class="name">中国企业公民责任品牌60强</p>
            </div></el-col
          >
        </el-row>
      </div>
      <div class="honorList">
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="item">
              <img :src="require('../assets/image/newAbout/honor-5.png')" />
              <p class="name">浦东开发开放30周年优秀企业</p>
            </div></el-col
          >
          <el-col :span="6">
            <div class="item">
              <img :src="require('../assets/image/newAbout/honor-6.png')" />
              <p class="name">浦东总部经济十大经典样本</p>
            </div></el-col
          >
          <el-col :span="6">
            <div class="item">
              <img :src="require('../assets/image/newAbout/honor-7.png')" />
              <p class="name">中国社会责任杰出企业</p>
            </div></el-col
          >
          <el-col :span="6">
            <div class="item">
              <img :src="require('../assets/image/newAbout/honor-8.png')" />
              <p class="name">浦东新区公益慈善奖</p>
            </div></el-col
          >
        </el-row>
      </div>
      <div class="honorList">
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="item">
              <img :src="require('../assets/image/newAbout/honor-9.png')" />
              <p class="name">中国品牌金信学院奖</p>
            </div></el-col
          >
          <el-col :span="6">
            <div class="item">
              <img :src="require('../assets/image/newAbout/honor-10.png')" />
              <p class="name">优秀商业模式创新奖</p>
            </div></el-col
          >
          <el-col :span="6">
            <div class="item">
              <img :src="require('../assets/image/newAbout/honor-11.png')" />
              <p class="name">援疆奉献奖</p>
            </div></el-col
          >
          <el-col :span="6">
            <div class="item">
              <img :src="require('../assets/image/newAbout/honor-12.png')" />
              <p class="name">
                中国老龄产业协会<br />
                《机构养老服务 健康管理服务规范》
                <br />
                团体标准制定荣誉表彰
              </p>
            </div></el-col
          >
        </el-row>
      </div>
      <div class="honorDate">
        <div class="list">
          <span class="date">2022年01月</span>
          <span class="desc">金恪集团被认定为上海市贸易型总部。</span>
        </div>
        <div class="list">
          <span class="date">2021年11月</span>
          <span class="desc"
            >金恪集团荣膺“2021卓越竞争力乡村振兴突出贡献奖”</span
          >
        </div>
        <div class="list">
          <span class="date">2021年01月</span>
          <span class="desc">金恪集团荣获“2020中国社会责任杰出企业奖”</span>
        </div>
        <div class="list">
          <span class="date">2020年09月</span>
          <span class="desc">金恪集团入选新华信用品牌信用示范创建企业。</span>
        </div>
        <div class="list">
          <span class="date">2020年09月</span>
          <span class="desc"
            >金恪集团荣膺投资时报“金禧奖·2020优秀商业模式创新奖”。</span
          >
        </div>
        <div class="list">
          <span class="date">2020年01月</span>
          <span class="desc">金恪集团被认定上海民营企业总部。</span>
        </div>
      </div>
      <div class="title">
        <span>在创业</span>
      </div>
      <div class="detailsImg">
        <img :src="require('../assets/image/newAbout/detailsImg-4.png')" />
      </div>
      <div class="details">
        <p class="desc">
          2022年是金恪集团的“再创业元年”，也是集团“共富共创”战略阶段的开启之年。金恪集团以“二十大”报告精神为指引，启动新的变革，进行创业式战略调整与组织再造。金恪将以幸福产业生态闭环优势，发挥产业运营能力与消费营销能力，用品牌、资源、平台、流量、金融优势让金恪成为产业增值模式的输出者和资本市场孵化器，为合作伙伴发展赋能，为地方经济发展助力，为实现中国人民共同富裕贡献民企力量。
        </p>
      </div>
    </div>
    <div class="footer">
      <div class="footerDetails">
        <div class="num">版权所有 ©2020金恪集团沪ICP备17027599号-3</div>
        <div class="address">
          <p class="addressList">
            <span class="city">上海地址：</span
            >上海市浦东新区杨高南路799号陆家嘴世纪金融广场27、28楼
          </p>
          <p class="addressList">
            <span class="city">北京地址：</span
            >北京市东城区珠市口东大街15-2金恪大厦
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "vueName",
  data() {
    return {
      msg: "Welcome to your vueName",
    };
  },
  mounted() {},
  methods: {},
  created() {},
  components: {},
};
</script>

<style scoped lang="scss">
img {
  width: 100%;
}
.top {
  height: 96px;
  width: 100%;
  background-color: #4c4948;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 180px;
    height: 60px;
  }
}
.topheader {
  height: 353px;
  background-image: url("../assets/image/newAbout/img1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.main {
  max-width: 1100px;
  margin: 30px auto;
  .title {
    width: 100%;
    height: 28px;
    position: relative;
    border-color: #c8996c;
    border-style: solid;
    span {
      display: block;
      position: absolute;
      left: 0px;
      top: -16px;
      background: #ffffff;
      line-height: 32px;
      font-weight: 400;
      padding: 0 5px;
      font-size: 20px;
      color: #caa574;
    }
  }
  .details {
    margin: 30px 0;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #3e3a39;
    line-height: 28px;
    .desc {
      margin: 10px 0;
    }
  }
  .detailsImg {
    margin: 30px 0;
  }
  .courseDetaisl {
    display: flex;
    align-items: center;
    margin: 18px 0;
    .left {
      width: 156px;
      text-align: center;
      border-right: 1px solid #d0a16c;
      .date {
        font-size: 14px;
        font-family: Impact;
        font-weight: 400;
        color: #b9c3c9;
        line-height: 28px;
      }
      .name {
        font-size: 16px;
        font-family: PingFang;
        font-weight: 300;
        color: #d0a16c;
        line-height: 32px;
      }
    }
    .right {
      margin-left: 12px;
      width: 924px;
      .desc {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #3e3a39;
        line-height: 28px;
      }
    }
  }
  .honorList {
    margin: 20px 0;
    .name {
      text-align: center;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #b2836d;
      line-height: 22px;
      padding: 10px;
    }
  }
  .honorDate {
    font-size: 16px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #3e3a39;
    line-height: 28px;
    margin-bottom: 20px;
    .list {
      padding: 10px 0;
    }
    .desc {
      padding: 0 20px;
    }
  }
}
.footer {
  height: 100px;
  width: 100%;
  background-color: #4c4948;
  .footerDetails {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-items: center;
    .num {
      line-height: 100px;
      font-size: 18px;
      font-family: PingFang;
      font-weight: bold;
      color: #b18068;
    }
    .address {
      font-size: 16px;
      font-family: PingFang;
      font-weight: bold;
      color: #8f9090 ;
      .addressList{
        padding: 10px 0;
      }
      .city {
        color: #b18068;
      }
    }
  }
}
</style>
