<template>
<div>
  <NavBar></NavBar>
  <div class="happiness">
    <div class="happiness_top_img"></div>
    <el-row class="happiness_cont">
      <el-col class="left">
        <div
            class="nav_item"
            v-for="(item, i) in leftList"
            :key="i"
            @click.stop="selectItem(i)"
        >
          <p>{{ item.name }}</p>
          <img
              class="icon"
              v-if="i === index"
              src="../../assets/image/core/core-1-8.png"
              alt=""
          />
        </div>
      </el-col>
      <el-col class="right">
        <div>
          <div class="rightItem">
            <div class="con_title">
              <span>幸福产业</span>
            </div>
            <div class="desc">
                金恪集团深度布局民生产业，精耕细作幸福生态，从农业食品到文化教育，从产品服务到产业规模，紧紧围绕国民大健康，逐步形成“医 食 住 行 养 娱”独特完整的幸福民生产业闭环，为人民生活需求提供整合式解决方案。
            </div>
            <div class="desc_details">
              <div class="desc_item">
                <div class="item_icon">
                  <img src="../../assets/image/core/happiness/icon1.png" alt="">
                </div>
                <p class="title">智慧医疗</p>
              </div>
              <div class="desc_item">
                <div class="item_icon">
                  <img src="../../assets/image/core/happiness/icon2.png" alt="">
                </div>
                <p class="title">农业食品</p>
              </div>
              <div class="desc_item">
                <div class="item_icon">
                  <img src="../../assets/image/core/happiness/icon3.png" alt="">
                </div>
                <p class="title">产业地产</p>
              </div>
              <div class="desc_item">
                <div class="item_icon">
                  <img src="../../assets/image/core/happiness/icon4.png" alt="">
                </div>
                <p class="title">文旅度假</p>
              </div>
              <div class="desc_item">
                <div class="item_icon">
                  <img src="../../assets/image/core/happiness/icon5.png" alt="">
                </div>
                <p class="title">幸福康养</p>
              </div>
              <div class="desc_item">
                <div class="item_icon">
                  <img src="../../assets/image/core/happiness/icon6.png" alt="">
                </div>
                <p class="title">文化艺术</p>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="item rightItem">
            <div class="item_img">
              <img src="../../assets/image/core/happiness/img1.png" alt="">
            </div>
          </div>
          <div class="line"></div>
          <div class="item rightItem">
            <div class="item_img">
              <img src="../../assets/image/core/happiness/img2.png" alt="">
            </div>
          </div>
          <div class="line"></div>
          <div class="item rightItem">
            <div class="item_img">
              <img src="../../assets/image/core/happiness/img3.png" alt="">
            </div>
          </div>
          <div class="line"></div>
          <div class="item rightItem">
            <div class="item_img">
              <img src="../../assets/image/core/happiness/img4.png" alt="">
            </div>
          </div>
          <div class="line"></div>
          <div class="item rightItem">
            <div class="item_img">
              <img src="../../assets/image/core/happiness/img5.png" alt="">
            </div>
          </div>
          <div class="line"></div>
          <div class="item rightItem">
            <div class="item_img">
              <img src="../../assets/image/core/happiness/img6.png" alt="">
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <Footer></Footer>
</div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
export default {
  name: "happiness",
  data(){
    return{
      leftList: [
        { name: "幸福产业", id: 0 },
        { name: "医", id: 1 },
        { name: "食", id: 2 },
        { name: "住", id: 3 },
        { name: "行", id: 4 },
        { name: "养", id: 5 },
        { name: "娱", id: 6 },
      ],
      index: 0,
      element: {
        nav_item: null,
        right_item: null,
      },
      timer: null,
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.rightScrollEventListener);
  },
  methods:{
    // 初始化
    init() {
      this.element = {
        nav_item: document.getElementsByClassName("nav_item"),
        right_item: document.getElementsByClassName("rightItem"),
      };
      this.element.nav_item[0].classList.add("colorActive");
      window.addEventListener("scroll", this.rightScrollEventListener);
    },
    // 页面滚动监听,定位导航
    rightScrollEventListener() {
      const { nav_item, right_item } = this.element;
      let window_scrollTop =
          document.scrollTop || document.documentElement.scrollTop;
      // const con = document.getElementsByClassName('con')[0].offsetTop

      for (let i = 0; i < right_item.length; i++) {
        if (window_scrollTop >= right_item[0].offsetTop + 100) {
          document.getElementsByClassName("left")[0].classList.add("leftFixed");
        } else {
          document
              .getElementsByClassName("left")[0]
              .classList.remove("leftFixed");
        }
        let item_offsetTop = right_item[i].offsetTop + 20 * i;

        if (window_scrollTop >= item_offsetTop) {
          for (let n = 0; n < nav_item.length; n++) {
            const current_classList = nav_item[n].classList;
            if (i === n) {
              current_classList.add("colorActive");
              this.index = n;
            } else {
              current_classList.remove("colorActive");
            }
          }
        }
      }
    },
    // 楼层导航驱动方法
    selectItem(i) {
      const { right_item } = this.element;
      let rightItem_offsetTop = right_item[i].offsetTop + 50;
      let window_scrollTop = document.scrollTop || document.documentElement.scrollTop;
      let timer = {
        step: 50,
        times: 10,
        rightItem_offsetTop,
      };
      if (window_scrollTop < rightItem_offsetTop) {
        this.setScrollArrowUp(timer);
      } else if (window_scrollTop == rightItem_offsetTop) {
        return false;
      } else {
        this.setScrollArrowDown(timer);
      }
    },
    // 设置页面滚动
    setScrollArrowUp(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
            document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop <= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop - v.step;
          document.body.scrollTop = window_scrollTop - v.step;
        }
      }, v.times);
    },
    // // 设置页面向下滚动
    setScrollArrowDown(v) {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        let window_scrollTop =
            document.scrollTop || document.documentElement.scrollTop;
        if (window_scrollTop >= v.rightItem_offsetTop) {
          document.documentElement.scrollTop = v.rightItem_offsetTop;
          document.body.scrollTop = v.rightItem_offsetTop;
          clearInterval(this.timer);
        } else {
          document.documentElement.scrollTop = window_scrollTop + v.step;
          document.body.scrollTop = window_scrollTop + v.step;
        }
      }, v.times);
    },
  },
  components: {
    Footer,
    NavBar,
  },
}

</script>

<style lang="scss" scoped>
.happiness{
  box-sizing: border-box;
  .happiness_top_img{
    height: 354px;
    width: 100%;
    background-image: url("../../assets/image/core/happiness/bg.png");
    background-size: 100%, 100%;
    background-repeat: no-repeat;
  }
  .happiness_cont{
    .left {
      width: 198px;
    }
    .right{
      padding: 89px 360px 0 666px;
      .con_title {
        width: 100%;
        height: 27px;
        border-bottom: 1px solid #c8a062;
        border-top: 1px solid #c8a062;
        border-right: 1px solid #c8a062;
        border-left: 1px solid #c8a062;
        position: relative;
        margin-bottom: 20px;
        span {
          display: block;
          position: absolute;
          left: 0px;
          top: -20px;
          background: #ffffff;
          font-size: 32px;
          line-height: 32px;
          font-weight: 400;
          color: #c8a062;
          width: 200px;
          padding-left: 30px;
          box-sizing: border-box;
        }
      }
      .desc{
        font-weight: 400;
        font-size: 16px;
        color: #3F3A39;
        line-height: 28px;
      }
      .desc_details{
        margin: 58px 0;
        display: flex;
        justify-items: center;
        justify-content: space-around;
        justify-self: center;
        .desc_item{
          .item_icon{
            width: 90px;
            height: 90px;
            margin: 0 auto;
            img{
              width: 100%;
            }
          }
          .title{
            margin: 20px 0;
            font-weight: 400;
            font-size: 16px;
            text-align: center;
            color: #C8996C;
          }
        }
      }
      .line{
        height: 2px;
        background-color: #c8a062;
      }
      .item{
        .item_img{
          margin: 40px 0;
          height: 538px;
          img{
            width: 100%;
          }
        }
      }
    }
    .nav_item {
      color: #3e3a39;
      width: 198px;
      position: relative;
      :hover {
        cursor: pointer;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 47px;
        height: 46px;
        border-bottom: 1px solid #e0e0e0;
        width: 100%;
        text-align: center;
      }
      .icon {
        display: block;
        width: 198px;
        height: 47px;
        position: absolute;
        left: 0;
        top: 0;
      }
      .fcolor {
        color: #c8a062;
      }
    }

  }

  .left {
    position: absolute;
    top: 80px;
    left: 360px;
  }
  .leftFixed {
    position: fixed !important;
    left: 360px !important;
    top: 140px !important;
  }
  .colorActive {
    color: #c8a062 !important;
  }
  .rt {
    margin-right: 75px;
  }
}
</style>